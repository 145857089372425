import * as React from 'react';
import * as DateTime from 'react-datetime';
import CheckBox from 'rc-checkbox';
import {Link} from 'react-router-dom';
import ROUTES from 'platform/constants/routes';
import Settings from 'platform/services/settings';
import {byRoute} from 'platform/decorators/routes';
import HelperComponent from 'platform/classes/helper-component';
import LoaderContent from 'components/loader-content';
import {countryCode} from 'platform/constants';
import {OrderDeliveryTimeTypeEnum, OrderDeliveryTypeEnum} from 'platform/api/order/constants/enums';
import Select from 'components/select';
import {OrderDeliveryTimeTypeDropdown, OrderDeliveryTypeDropdown} from 'platform/constants/dropdowns';
import {IOrderCreateRequestModel} from 'platform/api/order/models/request';
import Storage from 'platform/services/storage';
import {IDropdownOption, IResponse} from 'platform/constants/interfaces';
import {validateForm} from './services/helper';
import {formatDate, formatPrice} from 'platform/services/helper';
import ChooseAddress from './components/choose-address';
import {IUserAddressListResponseModel} from 'platform/api/userAddress/models/response';
import OrderController from 'platform/api/order';
import SuccessModal from 'components/success-modal';
import {CardTypeEnum, PaymentTypeEnum} from 'platform/constants/enums';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import PaymentMethod from './components/payment';
import {IBonusCardDetailsWithHistoryResponseModel} from 'platform/api/bonusCard/models/response';
import BonusCardController from 'platform/api/bonusCard';
import NumberInput from 'components/number-input';
import {IOrderResultResponseModel} from 'platform/api/order/models/response';
import Connection from 'platform/services/connection';
import {IPharmacyBranchListResponseModel} from 'platform/api/pharmacyBranch/models/response';
import UserAddressController from 'platform/api/userAddress';
import {Shared} from 'modules';
import './style.scss';
import LocationImage from 'assets/images/placeholder.svg';
import LocationHomeIcon from '../../../assets/images/icons/location-home.svg';
import TruckIcon from '../../../assets/images/icons/truck.svg';
import Arrow from '../../../assets/images/ic_arrow.svg'
import {
    IBasketResponseModel
} from "../../../platform/api/basket/models/response";
import BasketController from "../../../platform/api/basket";
import PhotoStorage from "../../../platform/services/photoStorage";
import {PromoNotValid} from "../cart/components/promoNotValid";
import MasterCard from "../../../assets/images/master.svg";
import VisaCard from "../../../assets/images/visaImage.svg";
import Environment from "../../../platform/services/environment";
import GtagData from "../../../platform/services/gtagData";
import AutoComplate from 'components/inputAutocomplate';
import ScrollPageLoader from 'components/page-loader/scrollLoader';
import ShimmerLoading from 'components/loader-content/shimmerLoading';
import ClickOutside from 'components/click-outside';
import * as asMoment from 'moment-timezone';
import moment from 'moment';
import { AddressIconSvg } from 'components/svg-components';
import { generateAddress, getSelectedDate } from '../cart/utils';
import { createFormAndSubmit } from './utils';


declare global {
    interface Window {
        gtag: any;
    }
}

enum dateTime {
    showDay = 1,
    showTime = 2
}

interface IState {
    balanceCount:number;
    openPopUpDate:boolean;
    data?: IBasketResponseModel;
    outOfStockConfirm: boolean;
    priceNotEnoughModalOpen: boolean;
    promoValid?: boolean;
    promoMessage?: "Promo is not valid",
    bonusDetails?: IBonusCardDetailsWithHistoryResponseModel;
    resultInfo?: IOrderResultResponseModel;
    form: IOrderCreateRequestModel;
    promoResponseText?: any;
    dateType: OrderDeliveryTimeTypeEnum;
    submitLoading: boolean;
    choosePharmacyOpen: boolean;
    cardType: number;
    chooseAddressOpen: boolean;
    authModalOpen: boolean;
    successModalOpen: boolean;
    chosenBranch?: IPharmacyBranchListResponseModel;
    addressList: IUserAddressListResponseModel[];
    initialTotalDiscountedPrice: number;
    isUsingBonus: boolean;
    idramAmount: number | null;
    idramNId: number | null;
    isFinishPage:boolean;
    promocodeMessage:boolean;
    visaPromocodeMessage:boolean,
    visaPromocodeMessageGuest:boolean,
    visaPromocodeBool:boolean,
    authOpen: boolean;
    smallWindow: boolean;
    showPersonalInfo: boolean;
    showMyAddresses: boolean;
    inputBool: boolean;
    promoCode: boolean;
    visaPromoTotal: number;
    visaPromoBonus: number;
    cleared: boolean;
    anotherPromo:boolean,
    submited:boolean,
    dateAndTime:any,
    showDayKey:any,
    showTimeKey:any,
    isloading:boolean
};

interface GtagSubmitData {
    COUPON: string,
    VALUE: number | undefined,
}

export enum responseEnum {
    success,
    no_such_promo,
    card_missing,
    invalid_card,
    promo_limit_expired,
    card_limit_expired,
    does_not_make_sense,
    promo_percent_is_smoller,
    delevery_does_not_make_sense
};

@byRoute(ROUTES.CHECKOUT)
class Checkout extends HelperComponent<{}, IState> {

    public state: IState = {
        openPopUpDate:false,
        outOfStockConfirm: false,
        priceNotEnoughModalOpen: false,
        promoValid: false,
        promoMessage: "Promo is not valid",
        submitLoading: false,
        choosePharmacyOpen: false,
        promoResponseText: null,
        cardType: 0,
        authOpen: false,
        chooseAddressOpen: false,
        successModalOpen: false,
        resultInfo: {
            totalPrice: 0,
            deliveryFee: 0,
            receivedBonus: 0,
            receivedBonusWithoutPromo: 0,
            totalDiscountedPrice: 0,
            isBonus: 0,
            isBonusVisa: 0,
            multiply: 0,
            totalDiscountedPriceWithoutPromo:0,
            totalPriceWithoutPromo: 0
        },
        authModalOpen: false,
        isUsingBonus: false,
        addressList: [],
        initialTotalDiscountedPrice: 0,
        dateType: OrderDeliveryTimeTypeEnum.Asap,
        form: {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            deliveryType: OrderDeliveryTypeEnum.Delivery,
            paymentType: PaymentTypeEnum.Cash,
            deliveryDateFrom:undefined
        },
        isloading:false,
        idramAmount: null,
        idramNId: null,
        isFinishPage:false,
        promocodeMessage:false,
        visaPromocodeMessage:false,
        visaPromocodeBool:true,
        smallWindow: false,
        showPersonalInfo: false,
        showMyAddresses: true,
        inputBool: true,
        visaPromocodeMessageGuest: false,
        promoCode: false,
        visaPromoTotal: 0,
        visaPromoBonus: 0,
        cleared:false,
        anotherPromo:false,
        submited:false,
        balanceCount:0,
        dateAndTime:{
            dateOption: [
                { id:1,title:Settings.translations.day_date_today,isActive:true },
                { id:2,title:Settings.translations.day_date_tmorrow,isActive:false }
            ],
            timeOption: [],
            selectedTimeOption:1,
            allAvailableHours:[],
        },
        showDayKey:undefined,
        showTimeKey:undefined,
    };

    private get formValidation() {
        const {submited,form} = this.state;
        return validateForm?.call(form,submited);
    }

    public componentDidMount() {
        const {form} = this.state;

        const query = new URLSearchParams(window.location.search);
        window.addEventListener(DispatcherChannels.refreshCheckout, this.RefreshWhenDataComing);
        query.delete('paymentType');
        query.delete('card');
        window.history.replaceState({path: window.location.pathname}, '', `?${query}`);
        this.getResultInfo().then(()=>{this.fetchData()});

        if (Storage.profile) {
            form.firstName = Storage.profile.firstName;
            form.lastName = Storage.profile.lastName;
            form.phoneNumber = Storage.profile.phoneNumber;
            form.email = Storage.profile.email;
            this.getTotalPrice();
            this.fetchAddressesList();
            this.safeSetState({form});
            this.getQuery();
        }else{
            this.safeSetState({visaPromocodeMessageGuest: true})
        }
        this.updateBasketCount();
        this.getBonusBalance();        
        this.getAvailableDate();        
    }
    
    private RefreshWhenDataComing = () => {
        if (Storage.profile) {
            const {form} = this.state;

            form.firstName = Storage.profile.firstName;
            form.lastName = Storage.profile.lastName;
            form.phoneNumber = Storage.profile.phoneNumber;
            form.email = Storage.profile.email;
            this.getTotalPrice();
            this.fetchAddressesList();
            this.safeSetState({isloading:true,form});
            this.getQuery();
        }
    }

    private HandleShowDayOption(optionKey:any) {
        const {showDayKey} = this.state
        if(showDayKey === undefined) {
            this.setState({showDayKey:optionKey})
        } else if(showDayKey === optionKey) {
            this.setState({showDayKey:undefined})
        } else {
            this.setState({showDayKey:optionKey})
        }
    }

    private HandleShowTimeOption(optionKey:any) {
        const {showTimeKey} = this.state
        if(showTimeKey === undefined) {
            this.setState({showTimeKey:optionKey})
        } else if(showTimeKey === optionKey) {
            this.setState({showTimeKey:undefined})
        } else {
            this.setState({showTimeKey:optionKey})
        }
    }

    private async getAvailableDate() {
        const response = await fetch("https://nataliapi.innodream.com/api/Settings/AvailableOrderDates").then((resp) => resp.json())
        const {data,success} = response
        const allAvailableHours = data.availableHours.map((dates:any,index:number) => {
            const givenTime = moment(dates.date, moment.ISO_8601, true); 
            const day = givenTime.format('YYYY-MM-DD');
            const fromTime = givenTime.format('HH:mm');
            const toTime = moment(fromTime, 'HH:mm').add(30, 'minutes').format('HH:mm');            
            const isToday = givenTime.isSame(moment(), 'day');
            
            return { 
                id:index,
                isToday,
                day,
                fromTime,
                toTime,
                isAvailable:dates.isAvailable,
                isActive: (index === 0)
            }
        })
        const todayData = allAvailableHours.filter((val:any) => val.isToday)

        if(success) {
            this.setState({
                dateAndTime: {
                    ...this.state.dateAndTime, 
                    timeOption: todayData,
                    allAvailableHours
                }
            });
        }
    }

    private HandleChoosDay(optionKey:number) {
        const {dateAndTime} = this.state
        
        const newOption = dateAndTime.dateOption.map((val:any) => {
            if (val.id === optionKey) {
              return { ...val, isActive: true };
            }
            return { ...val, isActive: false };
        });

        const SeletctedDay = newOption.find((val:any) => val.isActive)

        const filteredData = SeletctedDay.id === 1 ? dateAndTime.allAvailableHours.filter((val:any) => val.isToday) : dateAndTime.allAvailableHours.filter((val:any) => !val.isToday)
        const newTimeOption = filteredData.map((val:any,index:number) => {
            return {...val,isActive: (index === 0)}
        })

        this.setState({
            dateAndTime: {
                ...this.state.dateAndTime, 
                dateOption: newOption,
                timeOption:newTimeOption
            }
        });
    }

    private HandleChoosTime(optionId:number) {
        const {dateAndTime} = this.state
        let deliveryTime:any;
        const newOption = dateAndTime.timeOption.map((val:any) => {
            if (val.id === optionId) {
                deliveryTime = { ...val, isActive: true };
                return { ...val, isActive: true };
            }
            return { ...val, isActive: false };
        });

        if(deliveryTime?.day && deliveryTime?.fromTime) {
            const fromDateTime = asMoment.tz(`${deliveryTime.day} ${deliveryTime.fromTime}`, 'YYYY-MM-DD HH:mm', 'UTC').utcOffset('+04:00', true);
            this.setState({
                form: {
                    ...this.state.form, 
                    deliveryDateFrom: fromDateTime.format('YYYY-MM-DDTHH:mm:ssZ')
                },
                dateAndTime: {
                    ...this.state.dateAndTime, 
                    timeOption: newOption 
                }
            });
        }
    }

    private getBonusBalance() {
        BonusCardController.GetBonusBalance().then((result) => {
            if(result.success) {
              this.setState({balanceCount:result.data.balance});
            }
        });
    }

    private getQuery() {
        const query = new URLSearchParams(window.location.search);
        const isCard = query.get('isCard');

        const card = query.get('card');
        const paymentType = query.get('paymentType');

        
        if (card && paymentType) {
            const {form} = this.state;
            form.paymentType = +paymentType;
            form.creditCardId = +card;
            this.safeSetState({form});
        }

        if (isCard) {
            query.delete('isCard');
        }
    }

    private getTotalPrice = () => {
        const query = new URLSearchParams(window.location.search);
        const price = query.get('total');
        this.safeSetState({idramAmount: `${price ? Math.round(+price) : price}`});
    };

    // private sendPromoCode = async (val: any,promo = false) => {
    //     if(!promo){this.safeSetState({anotherPromo: true})}
    //     const {resultInfo} = this.state
    //     if (resultInfo != null) {
    //         resultInfo.totalPrice = resultInfo.totalPriceWithoutPromo;
    //         this.safeSetState({ resultInfo });
    //     }
    //     if (!Storage.profile && !promo){
    //         this.safeSetState({promocodeMessage: true})
    //     }
    //     val && val !== "" && Connection.POST2({
    //         body: {
    //             "promoCode": val,
    //             "productIds": this.state.data?.items.map(item => item.productId)
    //         },
    //         action: 'PromoCodeResult',
    //         controller: "Order",
    //     }).then(res => {
    //         res.data === responseEnum.success ? this.succesPromoValid(res.data,promo) : this.setPromoValid(res,promo)
    //     }).catch(err => console.log(err));
    // };

    private getResultInfo = async (bonus = 0, notSendPromocode?: any) => {
        const {form, initialTotalDiscountedPrice} = this.state;
        const result = notSendPromocode ? await OrderController.GetResult({
            usingBonus: bonus,
            deliveryType: form.deliveryType,
        }) : await OrderController.GetResult({
            usingBonus: bonus,
            deliveryType: form.deliveryType,
            promoCode: this.state.form.promoCode
        })
        result.data && this.safeSetState({
            resultInfo: result.data,
            initialTotalDiscountedPrice: Math.round(initialTotalDiscountedPrice || result.data.totalDiscountedPrice)
        });
        if (result.data && form.promoCode === Environment.PROMOCODE_NAME){
            this.safeSetState({visaPromoTotal: result.data.totalDiscountedPrice,visaPromoBonus:result.data.receivedBonus})
        }
    };

    private changeField = (e: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>,type=false) => {
        // if(type){
        //     this.safeSetState({promoResponseText: null,visaPromocodeBool:false,inputBool:true})
        // }
        const {form} = this.state;
        form[e.currentTarget.name] = e.currentTarget.value;
        this.safeSetState({form});
    };

    private bonusChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const {form} = this.state;

        form.useBonusAmount = +e.currentTarget.value;
        Connection.AbortAll();

        this.safeSetState({form}, () => this.getResultInfo(form.useBonusAmount));
    };

    private fetchAddressesList = async () => {
        const result = await UserAddressController.GetList();
        result.data.length && this.safeSetState({addressList: result.data}, this.checkForDefaultAddress);
    };

    private checkForDefaultAddress = () => {
        const {form, addressList} = this.state;
        const defaultAddress = addressList.find(item => item.isDefault === true);
        if (defaultAddress) {
            form.addressText = defaultAddress.addressText;
            form.addressLat = defaultAddress.addressLat;
            form.addressLng = defaultAddress.addressLng;
            form.addressBuilding = defaultAddress.building;
            form.addressEntrance = defaultAddress.entrance;
            form.addressApartment = defaultAddress.apartment;
            form.addressFloor = defaultAddress.floor;
            this.safeSetState({form});
        }
    };

    private changeDeliveryTimeTypeRadio = (chosen: OrderDeliveryTimeTypeEnum) => {
        const {form,dateAndTime}:any = this.state;
        if(chosen === OrderDeliveryTimeTypeEnum.Asap) {
            form.deliveryDateFrom = undefined;
        } else {
            const todayData = dateAndTime.timeOption.filter((val:any) => val.isToday)
            const newTime = asMoment.tz(`${todayData[0].day} ${todayData[0].fromTime}`, 'YYYY-MM-DD HH:mm', 'UTC').utcOffset('+04:00', true);
            form.deliveryDateFrom = newTime.format('YYYY-MM-DDTHH:mm:ssZ')
        }

        this.safeSetState({
            dateType: chosen, 
            form
        });
    };

    private toggleUsingBonus = async () => {
        const {isUsingBonus, form} = this.state;

        if (isUsingBonus) {
            form.useBonusAmount = 0;
            Connection.AbortAll();
            this.safeSetState({form}, () => this.getResultInfo(form.useBonusAmount));
        }

        this.safeSetState({isUsingBonus: !isUsingBonus});
    };

    private finishCheckout = async (e: React.SyntheticEvent) => {
        const {visaPromocodeBool,cardType} = this.state;
        e.preventDefault();
        
        this.safeSetState({isFinishPage: true}, () => {
            this.formValidation.valid && window.scrollTo(0, 0);
        });
        const data: GtagSubmitData = {
            COUPON: this.state.form.promoCode || "",
            VALUE: this.state.resultInfo?.totalPrice,
        }
        GtagData(data,"add_shipping_info")
        GtagData(data,"add_payment_info")

        const { form } = this.state;
        const query = new URLSearchParams(window.location.search);
        const card = query.get('card');
        form.paymentType = Number(query.get('paymentType'));
        form.creditCardId = card ? Number(card) : undefined;
        // let  total = this.state.resultInfo?.totalPriceWithoutPromo
        // if (form.paymentType === PaymentTypeEnum.Ameria && visaPromocodeBool && CardTypeEnum.Visa === cardType) {
        //     total = this.state.resultInfo?.totalPrice
        // }
        
        this.safeSetState({ submitLoading: true, form }, async () => {
            this.safeSetState({ submited: true }, () => {
                if (this.formValidation.valid) {
                    window.scrollTo(0, 0);
                }
            });
    
            if (this.formValidation.valid) {
                const result = await OrderController.Create(form);
    
                if (typeof result !== "string" && result.success) {
                    if (form.paymentType === PaymentTypeEnum.Idram) {
                        createFormAndSubmit('https://banking.idram.am/Payment/GetPayment', {
                            EDP_LANGUAGE: 'EN',
                            EDP_REC_ACCOUNT: result.data.idram.idramId,
                            EDP_DESCRIPTION: 'Order description',
                            EDP_AMOUNT: result.data.idram.totalToPay,
                            EDP_BILL_NO: result.data.id
                        });
                    } else {
                        const navigateTo = () => window.routerHistory.push(ROUTES.PROFILE.ORDERS.DETAILS.replace(':id', result.data.id));
                        navigateTo();
                        this.updateBasketCount();
                    }
                } else {
                    this.safeSetState({ submitLoading: false });
                }
            } else {
                this.safeSetState({ submitLoading: false });
            }
        });
    };

    private updateBasketCount = () => window.dispatchEvent(new CustomEvent(DispatcherChannels.CartItemsUpdate));

    private fetchData = async () => {

        // if (this.state.resultInfo?.isBonusVisa === 1){
        //     await this.sendPromoCode(Environment.PROMOCODE_NAME,true)
        //     this.safeSetState({inputBool:false});
        // }
        const result = await BasketController.GetList();
        this.safeSetState({data: result.data}, async () => {
            const {data,form} = this.state;
            if (data) {
                const photoResult = await Promise.all(data.items.map(item => PhotoStorage.getURL(item.productPhoto).then(url => ({
                    ...item,
                    productPhoto: url
                }))));
                data.items = photoResult;
                this.safeSetState({isloading:true, data});
            }
        });
    };

    private setPromoValid = (res?: any,promo=false) => {
        this.safeSetState({visaPromocodeMessage:false})
        this.getResultInfo(0, true)
        this.safeSetState({promoResponseText: "promoNotValid"})
        const {promoValid} = this.state;
        res && res.data && res.data !== responseEnum.success && this.safeSetState(
            res.data === responseEnum.invalid_card ? {promoMessage: res.data} :
                res.data === responseEnum.no_such_promo ? {promoMessage: res.data} :
                    res.data === responseEnum.card_missing ? {promoMessage: res.data} :
                        res.data === responseEnum.promo_limit_expired ? {promoMessage: res.data} :
                            res.data === responseEnum.does_not_make_sense ? {promoMessage: res.data} :
                                res.data === responseEnum.promo_percent_is_smoller ? {promoMessage: res.data} :
                                    res.data === responseEnum.delevery_does_not_make_sense ? {promoMessage: res.data} :
                                        res.data === responseEnum.card_limit_expired ? {promoMessage: res.data} : {promoMessage: ""});
        this.safeSetState({promoValid: !promoValid});
        if (promo){
            this.safeSetState({promoValid: false});
            this.safeSetState({promoMessage: ""})
            this.safeSetState({visaPromocodeBool: false})
        }
    };

    private succesPromoValid = (val: any,promo= false) => {
        const {form} = this.state;
        if (promo){
            this.safeSetState({visaPromocodeMessage:true})
            form.promoCode = Environment.PROMOCODE_NAME
            this.safeSetState({form})
            this.safeSetState({promoResponseText: ""})
            this.safeSetState({promoMessage: ""})
        }
        this.getResultInfo(form.useBonusAmount === 0 ? 0 : form.useBonusAmount);
        this.safeSetState({promoResponseText: val})
        if (form.promoCode !== Environment.PROMOCODE_NAME) {
            this.safeSetState({promoCode: true})
        }
    }

    private toggleAuthModal = () => {
        const {authModalOpen} = this.state;
        this.safeSetState({successModalOpen: false, authModalOpen: !authModalOpen});
    };

    private navigateToHome = () => window.routerHistory.push(ROUTES.HOME);

    private toggleAuth = () => {
        const {authOpen} = this.state;
        this.safeSetState({
            authOpen: !authOpen,
        });
    };

    private changeCheckout = (e:any) => {
        this.setState({ openPopUpDate:!this.state.openPopUpDate });
    };

    public render() {
        const {
            promoValid,
            cardType,
            form,
            dateType,
            submitLoading,
            resultInfo,
            authModalOpen,
            successModalOpen,
            initialTotalDiscountedPrice,
            isUsingBonus,
            isFinishPage,
            visaPromocodeMessage,
            authOpen,
            visaPromocodeBool,
            visaPromocodeMessageGuest,
            promoCode,
            visaPromoTotal,
            cleared,
            balanceCount,
            dateAndTime,
            showDayKey,
            showTimeKey,
            isloading
        } = this.state;
        const query = new URLSearchParams(window.location.search);
        const paymentType = query.get('paymentType');

        const SeletctedDay = dateAndTime.dateOption.find((val:any) => val.isActive)
        const SeletctedTime = dateAndTime.timeOption.find((val:any) => val.isActive)

        const date = getSelectedDate(SeletctedDay.id);
        return (
            <>
                <section className="G-page P-checkout-page">
                    {!isloading ? (<ShimmerLoading MClass={'checkoutLeftSideShimmer'} colCount={2} childColCount={1} className={"productShimmer"}/>) :
                    <div className="P-cart-left-box">
                        <h1 id="G-page-title"className="G-page-title">{Settings.translations.payment_method}</h1>
                        
                        <div className="first_section_form G-main-form">
                            <h1 className='reception_title'>{Settings.translations.Recipient_information}</h1>
                            <form className="P-checkout-form">
                                <div className="P-main-info G-half-width">
                                    <div className="G-main-form-field">
                                        <p className='title_input'>{Settings.translations.first_name}</p>
                                        <input
                                            autoComplete={'off'}
                                            name="firstName"
                                            value={form.firstName || ''}
                                            className={`G-main-input ${this.formValidation.errors.firstName ? 'G-invalid-field' : ''}`}
                                            placeholder={`${Settings.translations.Enter_input} ${Settings.translations.first_name}`}
                                            onChange={this.changeField}
                                        />
                                    </div>
                                    <div className="G-main-form-field">
                                        <p className='title_input'>{Settings.translations.last_name}</p>
                                        <input
                                            autoComplete={'off'}
                                            name="lastName"
                                            value={form.lastName || ''}
                                            className={`G-main-input ${this.formValidation.errors.lastName ? 'G-invalid-field' : ''}`}
                                            placeholder={`${Settings.translations.Enter_input} ${Settings.translations.last_name}`} 
                                            onChange={this.changeField}
                                        />
                                    </div>
                                    <div className='G-main-form-field'>
                                        <p className='title_input'>{Settings.translations.phone_number}</p>
                                        <div className="G-phone-input-wrapper">
                                            <div className='G-input-country-code-main'>
                                                <p className="G-input-country-code">+{countryCode}</p>
                                                <input
                                                    autoComplete={'off'}
                                                    name="phoneNumber"
                                                    value={form.phoneNumber || ''}
                                                    className={`G-main-input P-input-phone ${this.formValidation.errors.phoneNumber ? 'G-invalid-field' : ''}`}
                                                    placeholder={Settings.translations.phone_number}
                                                    onChange={this.changeField}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="second_section_form G-main-form">
                            <h1 className='reception_title'>{Settings.translations.Delivery_date_time}</h1>
                            {dateType === OrderDeliveryTimeTypeEnum.DeliveryDate &&
                                <h3 className='selectedTimeMain'>{date} {SeletctedTime.fromTime} - {SeletctedTime.toTime}</h3>
                            }
                            <div>
                                <div className={`data_time date-of-order-wrapper step-content ${this.state.openPopUpDate ? "showPopUp"  : "closePopUp"}`}>
                                    <div className="pseudo-element" onClick={this.changeCheckout}>{""}</div>
                                    <span>{Settings.translations.date_of_order}</span>
                                    <div className={`web-app-fields-wrapper`}>
                                        <div className={`G-main-form-field web-app-fields ${dateType === OrderDeliveryTimeTypeEnum.Asap ? 'active': ''}`}>
                                            <input className={`input_data`} defaultChecked={true} type="radio" name={'deliveryTime'} value={OrderDeliveryTimeTypeEnum.Asap} onChange={()=> this.changeDeliveryTimeTypeRadio(OrderDeliveryTimeTypeEnum.Asap)}/>
                                            {Settings.translations.asap}
                                        </div>
                                        <div className={`G-main-form-field web-app-fields ${dateType === OrderDeliveryTimeTypeEnum.DeliveryDate ? 'active': ''}`}>
                                            <input className={`input_data`}  type="radio" name={'deliveryTime'} value={OrderDeliveryTimeTypeEnum.DeliveryDate} onChange={()=>this.changeDeliveryTimeTypeRadio(OrderDeliveryTimeTypeEnum.DeliveryDate)}/>
                                            {Settings.translations.delivery_date}
                                        </div>
                                    </div>
                                    {dateType === OrderDeliveryTimeTypeEnum.DeliveryDate && 
                                    <div className='main_dropDown'> 
                                        <ClickOutside className='custom-select-outSide' onClickOutside={() => this.HandleShowDayOption(undefined)}>
                                            <div className="custom-select" onClick={(e) => {
                                                e.stopPropagation()
                                                this.HandleShowDayOption(dateTime.showDay)
                                            }}>
                                                <div className="selected-option">{SeletctedDay.title}</div>
                                                {(showDayKey === dateTime.showDay) && (
                                                    <div className="options">
                                                        {dateAndTime?.dateOption?.map((option:any, index:number) => (
                                                            <div 
                                                                key={index}  
                                                                className={`optionDetail ${option.isActive ? 'optionDetailActive' : '' }`} 
                                                                onClick={() => this.HandleChoosDay(option.id)}>
                                                                {option.title}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </ClickOutside>

                                        <ClickOutside className='custom-select-outSide' onClickOutside={() => this.HandleShowTimeOption(undefined)}>
                                            <div className="custom-select" onClick={(e) => {
                                                e.stopPropagation()
                                                this.HandleShowTimeOption(dateTime.showTime)
                                            }}>
                                                <div className="selected-option">{SeletctedTime.fromTime} - {SeletctedTime.toTime}</div>
                                                {(showTimeKey === dateTime.showTime) && (
                                                    <div className="options timeOptionDetail">
                                                        {dateAndTime?.timeOption?.map((option:any, index:number) => (
                                                            <button 
                                                                disabled={!option.isAvailable}
                                                                key={index}  
                                                                className={`optionDetail ${option.isActive ? 'optionDetailActive' : '' }`} 
                                                                onClick={() => this.HandleChoosTime(option.id)}>
                                                                {option.fromTime} - {option.toTime}
                                                            </button>))
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                        </ClickOutside>
                                    </div>}
                                </div>
                                <button className='chooseDate' onClick={this.changeCheckout}>
                                    {Settings.translations.choose_date}
                                </button>
                            </div>
                        </div> 

                        <div className="second_section_form G-main-form">
                            <h1 className='reception_title'>{Settings.translations.Delivery_address}</h1>
                            <PaymentMethod 
                                resultInfo={resultInfo} 
                                form={form} 
                                callback={(e: React.SyntheticEvent) => this.finishCheckout(e)} 
                                setCardType={(e: number) => this.setState({cardType:e})}
                            />

                            {!!balanceCount && <div className="P-bonus-field G-mb-25">
                                <div className='P-bonus field_title'>
                                    <CheckBox checked={isUsingBonus} onClick={this.toggleUsingBonus}/>
                                    <span className="G-ml-12">{Settings.translations.use_bonus_points}</span>
                                    {isUsingBonus && <span className="G-ml-auto G-text-bold P-bonus-point-checked">{balanceCount}</span>}
                                </div>


                                {isUsingBonus && <>
                                    <p className='title_input'>{Settings.translations.Used_amount}</p>
                                    <NumberInput
                                        max={Math.min(initialTotalDiscountedPrice, balanceCount)}
                                        value={form.useBonusAmount || ''}
                                        className="G-main-input"
                                        onChange={this.bonusChange}
                                        placeholder={`${Settings.translations.Write_input} ${Settings.translations.only_amount}`} 
                                    />
                                </>}
                            </div>}
                        </div>
                                        
                        {successModalOpen && <SuccessModal onClose={this.navigateToHome}>
                                {Settings.guest ? <>
                                    <h3>{Settings.translations.guest_order_success}</h3>
                                    <button id={'sign_up_btn'} className="G-main-button G-normal-link G-mt-30 P-register-button"
                                            onClick={this.toggleAuthModal}>{Settings.translations.sign_up}</button>
                                </> : <>
                                    <h3>{Settings.translations.order_success}</h3>
                                    <Link 
                                        className="G-main-button G-normal-link G-mt-30"
                                        to={ROUTES.PROFILE.ORDERS.MAIN}>{Settings.translations.order_history}
                                    </Link>
                                </>}
                            </SuccessModal>}
                        {authModalOpen && <Shared.Auth onClose={this.toggleAuthModal}/>}
                        {/* <form action="https://money.idram.am/payment.aspx" method="POST">
                            <input type="hidden" name="EDP_LANGUAGE" value="EN"/>
                            <input type="hidden" name="EDP_REC_ACCOUNT" value="110000601"/>
                            <input type="hidden" name="EDP_DESCRIPTION" value="Order description"/>
                            <input type="hidden" name="EDP_AMOUNT"
                                   value={this.state.form.deliveryType === OrderDeliveryTypeEnum.Delivery ? this.state.resultInfo?.totalPrice : this.state.resultInfo?.totalDiscountedPrice || ''}/>
                            <input type="hidden" name="EDP_BILL_NO" value={this.state.idramNId || ""}/>
                            <input type="submit" value="submit" id="currentId" className="G-dn"/>
                        </form> */}
                    </div>}

                    {!resultInfo?.totalPrice ? (<ShimmerLoading MClass={'checkoutRightSideShimmer'} colCount={1} childColCount={1} className={"productShimmer"}/>) : 
                    <div className="P-cart-right-box">
                        <div className="P-data-block-wrapper addressCheckoutBlock">
                            <div className='AddressLine'>
                                <p className='address'>
                                    {Settings.translations.Delivery_address}
                                </p>
                            </div>
                            <div className='addressAndIcon'>
                                <span className='addressIcon'><AddressIconSvg/></span>
                                <p className='addressValue'>
                                    {`${Storage?.currentAddress?.addressText}, `}
                                    {generateAddress()}
                                </p>               
                            </div>
                        </div>


                        <div className={`P-data-block-wrapper`}>
                            {resultInfo && <>
                                <>
                                    {<h3 className="bonus_ G-clr-green G-text-semi-bold G-mb-25 G-mt-10 G-flex G-flex-justify-between">
                                        <span className=" G-text-semi-bold">{Settings.translations.gained_bonus}</span>
                                        <span className=" G-text-semi-bold">{resultInfo.receivedBonus}</span>
                                    </h3>}
                                    { resultInfo.isBonus > 0 && <h3 className=" G-text-semi-bold G-mb-25 G-mt-10 G-flex G-flex-justify-between">
                                        <span className="G-text-semi-bold master-card-text">{Settings.translations.accumulative_bonus_master_card}
                                            <img src={MasterCard} className="logo-image" width="30"  alt="MASTER CARD"/>
                                        </span> 
                                        <span className="G-text-semi-bold master-card-bonus">{resultInfo.receivedBonus * resultInfo.multiply } {Settings.translations.point}</span>
                                    </h3>}
                                    {visaPromocodeMessage  &&  <h3 className="G-text-semi-bold G-mb-25 G-mt-10 G-flex G-flex-justify-between">
                                        <span className="G-text-semi-bold master-card-text">{Settings.translations.accumulative_bonus_visa_card}<br/>
                                            <img src={VisaCard} className="logo-image" width="50"  alt="VISA CARD"/>
                                        </span>
                                        <span className="G-text-semi-bold master-card-bonus">{formatPrice(visaPromoTotal)}</span>
                                    </h3>}
                                </>

                                <h3 className="price_ G-mb-25 G-mt-auto G-text-semi-bold G-flex G-flex-justify-between">
                                    <span>{Settings.translations.price}</span>
                                    <span className="G-text-semi-bold">{formatPrice(resultInfo.totalDiscountedPriceWithoutPromo)}</span>
                                </h3>

                                {!!form.useBonusAmount &&
                                <h3 className="G-text-semi-bold G-mb-25 G-mt-10 G-flex G-flex-justify-between">
                                    <span className="G-text-semi-bold">{Settings.translations.used_bonus}</span>
                                    <span className="G-text-semi-bold">{form.useBonusAmount}</span>
                                </h3>}
                                
                                <div>
                                    <h3 className="price_ G-text-semi-bold G-mb-25 G-mt-10 G-flex G-flex-justify-between">
                                        <span>{Settings.translations.delivery}</span>
                                        <span className="G-text-semi-bold">{formatPrice(resultInfo.deliveryFee)}</span>
                                    </h3>
                                    <h2 className="G-mt-10 G-flex G-fs-22 G-flex-justify-between total_">
                                        <span>{Settings.translations.total}</span>
                                        <span>{formatPrice(resultInfo.totalPrice)}</span>
                                    </h2>
                                </div> 

                                {/* {form.deliveryType === OrderDeliveryTypeEnum.Delivery ?
                                    <div>
                                        <h3 className="price_ G-text-semi-bold G-mb-25 G-mt-10 G-flex G-flex-justify-between">
                                            <span>{Settings.translations.delivery}</span>
                                            <span className="G-text-semi-bold">{formatPrice(resultInfo.deliveryFee)}</span>
                                        </h3>
                                        <h2 className="G-mt-10 G-flex G-fs-22 G-flex-justify-between total_">
                                            <span>{Settings.translations.total}</span>
                                            <span>{
                                                (visaPromocodeMessage && isFinishPage && cardType === CardTypeEnum.Visa && Number(paymentType) === PaymentTypeEnum.Ameria && !cleared) || promoCode && Storage.profile && !cleared ?
                                                    formatPrice(resultInfo.totalPrice) :
                                                    (visaPromocodeMessage && isFinishPage && cardType === CardTypeEnum.Visa && Number(paymentType) === PaymentTypeEnum.Ameria && cleared ) ?
                                                    formatPrice(visaPromoTotal+resultInfo.deliveryFee) :
                                                    formatPrice(resultInfo.totalPriceWithoutPromo)
                                            }</span>
                                        </h2>
                                    </div> :
                                    <div>
                                        <h2 className="G-mt-10 G-flex G-fs-22 G-flex-justify-between">{Settings.translations.total}
                                            <span className="G-fs-22">
                                                {(visaPromocodeMessage && isFinishPage && cardType === CardTypeEnum.Visa && Number(paymentType) === PaymentTypeEnum.Ameria && !cleared) || promoCode && Storage.profile && !cleared  ?
                                                    formatPrice(resultInfo.totalDiscountedPrice) :
                                                    (visaPromocodeMessage && isFinishPage && cardType === CardTypeEnum.Visa && Number(paymentType) === PaymentTypeEnum.Ameria && cleared ) ?
                                                    formatPrice(visaPromoTotal+resultInfo.deliveryFee) : formatPrice(resultInfo.totalDiscountedPriceWithoutPromo)} 
                                            </span>
                                        </h2>
                                    </div>} */}
                                {/* {visaPromocodeMessage && visaPromocodeMessageGuest && !Storage.profile && isFinishPage && Number(paymentType) === PaymentTypeEnum.Ameria && cardType === CardTypeEnum.Visa &&  
                                    <p className={'promocodeMessage'}>
                                        {Settings.translations.accumulative_bonus_visa_card_guest}
                                        <a onClick={this.toggleAuth}>{Settings.translations.promocode_message_2}</a>
                                        {Settings.translations.promocode_message_3}
                                    </p>} */}
                            </>}

                            <div>
                                {window.location.href.includes('success')
                                    ? <SuccessModal onClose={() => window.location.href = '/'}>
                                        <h3>{Settings.translations.order_success}</h3>
                                        <Link className="G-main-button G-normal-link G-mt-30" to={ROUTES.PROFILE.ORDERS.MAIN}>{Settings.translations.order_history}</Link>
                                    </SuccessModal>
                                    : window.location.href.includes('fail')
                                        ? <SuccessModal onClose={() => window.location.href = '/'} bool1={true} bool2={true}>
                                            <h3>{Settings.translations.order_fail}</h3>
                                        </SuccessModal> : null
                                }
                                {promoValid && !visaPromocodeBool && <PromoNotValid onClose={this.setPromoValid} promoMessage={this.state.promoMessage}/>}
                                <div>
                                    <button 
                                        className="G-main-button P-pay-button bot"
                                        onClick={this.finishCheckout}
                                        id="order_process_end_btn"
                                    >
                                        {submitLoading ? <ScrollPageLoader /> : Settings.translations.pay}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>}
                </section>
                {authOpen && <Shared.Auth onClose={this.toggleAuth}/>}
            </>
        );
    }
};
export default Checkout;