import * as React from 'react';

import ROUTES from 'platform/constants/routes';
import { byRoute } from 'platform/decorators/routes';
import HelperPureComponent from 'platform/classes/helper-component';
import Settings from 'platform/services/settings';
import { IStaticResponseModel } from 'platform/api/static/models/response';
import StaticController from 'platform/api/static';

import './style.scss';
import {Link} from "react-router-dom";

interface IState {
  data?: IStaticResponseModel;
}

@byRoute([ROUTES.PRIVACY_POLICY])
class PrivacyPolicy extends HelperPureComponent<{}, IState> {

  public state: IState = {};

  public async componentDidMount() {
    const result = await StaticController.GetContentType(3);
    this.safeSetState({ data: result.data });
  }

  public render() {
        const {data} = this.state;
        return (
            <div >
                <ul className="Breadcrumbs">
                    <li className='fromRoutes'><Link to={ROUTES.MN}> {Settings.translations.home}</Link></li>
                    <li className='activeRoute'> {Settings.translations.privacy_policy
                    }</li>
                </ul>
                <section className="G-page P-privacy-policy-page">
                    <h2 className="G-page-title">{data?.title}</h2>
                    {data && <div className="P-content" dangerouslySetInnerHTML={{__html: data.description}}/>}
                    <div className="privacy-policy">
                        {Settings.translations.privacy_policy_text}
                    </div>
                </section>
            </div>
        );
    }
}
export default PrivacyPolicy;