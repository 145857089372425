import * as React from 'react';

import HelperComponent from 'platform/classes/helper-component';
import { byPrivateRoute } from 'platform/decorators/routes';
import ROUTES from 'platform/constants/routes';
import { onlyForUsers } from 'platform/guards/routes';
import Settings from 'platform/services/settings';
import './style.scss';
import Layout from '../../components/layout';
import * as copy from "copy-to-clipboard";
import BonusCardController from 'platform/api/bonusCard';

interface IState {
    link:string,
    bonusCount:number,
    inviteCount:number
}

@byPrivateRoute(ROUTES.PROFILE.MY_INVITES, [onlyForUsers])
class Invite extends HelperComponent<{}, IState> {
    public componentDidMount() {
        this.getLink();
    }

  public state: IState = {
      link:'',
      bonusCount:0,
      inviteCount:0,
  };

  private getLink = async () => {
      BonusCardController.GetReferralLink().then(result => {
          if ( result.data && result.success){
              this.safeSetState({ link: result.data.link,bonusCount:result.data.bonusCount,inviteCount:result.data.inviteCount});
          }
      });

  }

    public copyLink = async () => {
        const { link} = this.state;
        copy(link);
        const alertify = await import('alertifyjs');
        alertify.success(`
          <div class="MuiAlert_standardError_child">
              <strong>Success</strong>
              ${Settings.translations.copied_to_clipboard}
          </div>
        `);
    }

  public render() {
      const { link,bonusCount,inviteCount} = this.state;
    return (
        <Layout>
         <div className="P-invite">
          <div className="P-invite-child  G-mb-40" >
            <span className="P-invite-accumulation P-invite-image"/>
            <h1>{bonusCount}</h1>
            <p className="G-flex  G-mb-10 G-mt-20">
            <span>
              {Settings.translations.invited}
            </span>
            </p>
          </div>
          <div className="P-invite-child  G-mb-40" >
            <span className="P-invite-bonus P-invite-image"/>
            <h1>{inviteCount}</h1>
            <p className="G-flex G-mb-10 G-mt-20">
            <span>
              {Settings.translations.bonus}
            </span>
            </p>
          </div>
        </div>
        <h1 className=" G-mb-20 invite-friend">
            {Settings.translations.invite_your_friend}
        </h1>
            <p className="G-mb-10 G-mt-20">
                {Settings.translations.earn_bonus_points}
            </p>
            <p className="G-mb-10 G-mt-20">
                {Settings.translations.copy_and_share}
            </p>
            <div className="G-main-form-field G-mt-30 P-link-wrapper">
                <input
                    readOnly={true}
                    defaultValue={link}
                    className={`G-main-input`}
                />
                <button id="copy_link_btn" className="copy-link" onClick={this.copyLink} />
            </div>

       </Layout>
    );
  }
}

export default Invite;
