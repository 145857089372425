import * as React from 'react';

import Settings from 'platform/services/settings';
import HelperComponent from 'platform/classes/helper-component';
import LoaderContent from 'components/loader-content';
import { ModalContentEnum } from '../../constants/enums';
import { ILoginRequestModel } from 'platform/api/auth/models/request';
import UserController from 'platform/api/user';
import { validateFormBonusCard } from '../signin/services/bonusValidation';
import BonusCardController from 'platform/api/bonusCard';

interface IProps {
  onTypeChange<ActiveData extends object>(type: ModalContentEnum, activeData?: ActiveData): void;
  activeData:any,
  onChangeBonusCard:any
};

interface IState {
  form: ILoginRequestModel;
  submited: boolean;
  submitLoading: boolean;
  activeData:any;
};

class BonusCardRegister extends HelperComponent<IProps, IState> {

  public state: any = {
    submited: false,
    submitLoading: false,
    form: {
      bonusCode: '',
      pinCode: '',
      Xcode:''
    },
    backendErrors:false,
    activeData:null
  };

  public componentDidMount() {
    (async () => {
        const { activeData } = this.props
        const { form } = this.state
        const result = await BonusCardController.GetBonusCode(activeData);

        form.Xcode = result.data
        this.setState({form})
    })()
  }

  private get formValidation() {
    const { submited, form, backendErrors } = this.state;
    return validateFormBonusCard?.call(form, submited,backendErrors);
  }

  private signUp = () => {
    const { onTypeChange } = this.props;
    onTypeChange(ModalContentEnum.SignUp, { signUp: true });
  }

  private changeField = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { form } = this.state;
    form[e.currentTarget.name] = e.currentTarget.value;
    this.safeSetState({ form })
  }

  private submit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const form = {...this.state.form};
    this.safeSetState({ submited: true }, async () => {
      this.formValidation.valid && this.safeSetState({ submitLoading: true }, async () => {
        const result = await UserController.GetBonusDetail(form);
        if (result.success) {
          this.props.onChangeBonusCard(result.data)
          this.signUp();
        } else {
          this.safeSetState({ submitLoading: false ,backendErrors:true})
          setTimeout(() => {
            this.safeSetState({ submitLoading: false ,backendErrors:false})
          },3000)
        };
      });
    });
  }

  public render() {
    const { form, submitLoading } = this.state;
    return <>
      <h3 className="G-clr-main G-text-center">{Settings.translations.sign_up}</h3>
      <span className="P-sign-in-register-text">{`${Settings.translations.bonusCard_register_1} ${form?.Xcode} ${Settings.translations.bonusCard_register_2}`}</span>

      <form className="G-main-form">
        <div className="G-main-form-field firstField G-phone-input-wrapper G-mb-0">
          <p className='placeUpHolder'>{Settings.translations.bonus_card}</p>
          <div className='G-input-country-code-main'>
            <input
              autoComplete='off'
              name="bonusCode"
              // type='number'
              value={form.bonusCode}
              placeholder={Settings.translations.code_number}
              className={`G-main-input ${this.formValidation.errors.bonusCode ? 'G-invalid-field' : ''}`}
              onChange={this.changeField}
            />
          </div>

          <span className='P-error-message' style={{opacity: form.username && this.formValidation.errors.username ? 1 : 0}}>Phone number format is invalid</span>
        </div>
        <div className="G-main-form-field secondField">
          <p className='placeUpHolder'>{Settings.translations.pin_text}</p>
          <input
            name="pinCode"
            type='number'
            value={form.pinCode}
            placeholder={`${Settings.translations.Enter_input} ${Settings.translations.pin_text}`}
            className={`G-main-input ${this.formValidation.errors.pinCode ? 'G-invalid-field' : ''}`}
            onChange={this.changeField}
            autoComplete='off'
          />
        </div>
        <LoaderContent
          className="G-main-button"
          loading={submitLoading}
          onClick={this.submit}
          id={'login_btn'}
        >{Settings.translations.log_in}</LoaderContent>
      </form>
    </>;
  }
}

export default BonusCardRegister;
