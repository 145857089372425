import * as React from 'react';
import scrollToElement from 'scroll-to-element';

import ROUTES from 'platform/constants/routes';
import { byRoute } from 'platform/decorators/routes';
import HelperComponent from 'platform/classes/helper-component';
import { IPharmacyBranchListResponseModel, AddressType, CitiesResponseModel  } from 'platform/api/pharmacyBranch/models/response';
import PharmacyBranchController from 'platform/api/pharmacyBranch';

import './style.scss';
import Settings from 'platform/services/settings';
import RadioButton from 'components/radio/newRadio';
import CustomSelect from 'components/select/CostumSelect';
import NewSearchResults from './components/newSearchResult';
import MyMapComponent from './components/maps';
import OurPharmacyNewSearchResults from './components/newSearchResult/ourPharmacy';
import { SVGComponentLocation, SVGComponentMobileMenuIcon, SVGComponentMobileSearch, SVGFilterIcon } from 'components/svg-components';
import ShimmerLoading from 'components/loader-content/shimmerLoading';


interface IState {
  pharmacy:any,
  data: IPharmacyBranchListResponseModel[];
  cities:any,
  checked:boolean,
  selectedCityId:number,
  addressLat:number,
  addressLng:number,
  zoom:number,
  currentID:number
};









@byRoute([ROUTES.PHARMACIES])
class Pharmacies extends HelperComponent<{}, IState> {

  public state: IState = {
    data:[],
    pharmacy:[],
    cities:[],
    checked:false,
    selectedCityId:0,
    addressLat:40.179261, 
    addressLng:44.513238,
    zoom:12,
    currentID:0
  };

  public componentDidMount() {
    (async () => {
      const pharmacyData = await PharmacyBranchController.GetForPharmacy(this.state.selectedCityId);
      this.setState({pharmacy:pharmacyData.data})
      const citiesData = await PharmacyBranchController.GetForSelection();
      const initial = [{id: 0,name: Settings.translations.allCities}]
      const newcities = citiesData?.data?.concat(initial)
      this.setState({cities:newcities})
    })()
  }

  private handleOpenMap(checked:boolean) {
    this.setState({ checked });
  }

  private async handleChangeSelectCity(id:number) {
    const pharmacyData = await PharmacyBranchController.GetForPharmacy(id);
    this.setState({ selectedCityId:id,pharmacy:pharmacyData.data });
  }

  private changeShopLocation = async ( adressZoom: AddressType) => { 
    const { checked } = this.state;
    if(checked) {
      this.setState({...adressZoom,zoom:18});
    }
  }


  public render() {
    const { checked,selectedCityId,cities,pharmacy,addressLat,addressLng,zoom,currentID } = this.state;
    return (
        <div className={`pharmacy ${checked ? 'isMapOpenPharmacy' : ''}`}>
          <div className='pharmacy_header'>
            <div className='title_content'>
              <p className='title'> {Settings.translations.pharmacies} </p>
              <RadioButton isChecked={checked} callback={(check:boolean) => this.handleOpenMap(check)}/>
            </div>
            <div className='mobileTurnMapButton' onClick={() => this.handleOpenMap(!checked)}> 
              {checked ?  <SVGComponentMobileMenuIcon/> : <SVGComponentLocation/>}
            </div>
            <CustomSelect selectedCityId={selectedCityId} cities={cities} callBack={(id:number) => this.handleChangeSelectCity(id)} />
          </div>
          {pharmacy.length ?
          (<OurPharmacyNewSearchResults pharmacy={pharmacy} open={checked} currentID={currentID} callBack={(adressZoom:AddressType) => this.changeShopLocation(adressZoom)}/>) : 
          (<ShimmerLoading colCount={9} className={"pharmacyPageShimmer"}/>)
          }
          <div className='map_container'>
            <MyMapComponent
              pharmacy={pharmacy}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyB_EubnEYSSE5ZbeiGnSs4-ce-YWRj0cHQ`}
              loadingElement={<div style={{ height: '100%' }} />}
              containerElement={<div style={{ height: '100%' }} />}
              mapElement={<div style={{ height: '100%' }} />}
              centerMap={{ addressLat, addressLng, zoom }}
              currentID={currentID}
              callBack={(newLOcation:any) => this.setState(newLOcation)}
            />
          </div>
        </div>
    );
  }
}

export default Pharmacies;



