import * as React from 'react';
import './style.scss';
import Settings from 'platform/services/settings';
import { NotificationEmptySTateSvg } from 'components/svg-components';


const EmptyStateNotification= React.memo(() => {

  return (
    <div className='NotificationEmptyMain'>
      <NotificationEmptySTateSvg/>
      <span className='P-desc'>{Settings.translations.EmptyNotification}</span>
    </div>
  );
})

export default EmptyStateNotification;
