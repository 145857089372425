import Connection    from '../../services/connection';
import { IResponse, IPagingRequest, IPagingResponse } from '../../constants/interfaces';
import { IBlogListResponseModel, IBlogDetailsResponseModel } from './models/response';

class BlogController {

  private static controller = 'blog';

  public static GetList = (body: IPagingRequest): Promise<IResponse<IPagingResponse<IBlogListResponseModel>>> => {
    const result = Connection.POST({
      body,
      action: 'list',
      controller: BlogController.controller,
    });
    
    return result;
  };

  public static GetDetails = (id: number,slug:string=''): Promise<IResponse<IBlogDetailsResponseModel>> => {
    const result = Connection.GET({
      action: `${id}/${slug}`,
      controller: BlogController.controller,
    });

    return result;
  };
  public static Phone = (phone: number): Promise<IResponse<boolean>> => {
    const result = Connection.PUT({
      body: {},
      action: `phone/${phone}`,
      controller: BlogController.controller,
    });

    return result;
  };
};

export default BlogController;