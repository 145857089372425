import React from "react";
import { SVGComponentNextArrowIcon } from "components/svg-components";


export function SampleNextArrow(props:any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "red" }}
        onClick={onClick}
      >
        <SVGComponentNextArrowIcon/>
      </div>
    );
  }