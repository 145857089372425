import * as React from 'react';
import './style.scss';
import Settings from 'platform/services/settings';
import { AdressesEmptySTateSvg } from 'components/svg-components';


const EmptyStateAdresses = React.memo(() => {

  return (
    <div className='AdressesEmptyMain'>
      <AdressesEmptySTateSvg/>
      <span className='P-desc'>{Settings.translations.empty_address_list}</span>
    </div>
  );
})

export default EmptyStateAdresses;
