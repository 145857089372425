import * as React from 'react';
import Settings from 'platform/services/settings';
import Modal from 'components/modal';
import './style.scss';
interface IProps {
  onClose(): void;
  promoMessage?: any
};

export const PromoNotValid = React.memo(({ onClose,promoMessage }: IProps) => (
  <Modal onClose={onClose} className="P-cart-price-not-enough-modal">
    <div className="G-fields-form">
      <p>
          {promoMessage===1?Settings.translations.no_such_promo:
          promoMessage===2?Settings.translations.card_missing:
          promoMessage===3?Settings.translations.invalid_card:
          promoMessage===4?Settings.translations.promo_limit_expired:
          promoMessage===5?Settings.translations.card_limit_expired:
          promoMessage===6?Settings.translations.does_not_make_sense:
          promoMessage===7?Settings.translations.promo_percent_is_smoller:
          promoMessage===8?Settings.translations. delevery_does_not_make_sense:

          ""}
      </p>
      <button id={'form_btn'} onClick={onClose} className="G-form-button">{Settings.translations.ok}</button>
    </div>
  </Modal>
));