import * as React from 'react';

import Settings from 'platform/services/settings';
import Form from './components/form';
import AboutDelivery from './components/about-delivery';
import AboutBonusCard from './components/about-bonus-card';
import AboutMobileApp from './components/about-mobile-app';
import HelperComponent from 'platform/classes/helper-component';
import PharmacyBranchController from 'platform/api/pharmacyBranch';
import { formatTime, getViewEnum } from 'platform/services/helper';
import { WeekDaysEnum } from 'platform/constants/enums';
import { IPharmacyBranchListResponseModel } from 'platform/api/pharmacyBranch/models/response';
import MapIconGreen from 'assets/images/map-icon-green.svg';
import MapIconOrange from 'assets/images/map-icon-orange.svg';


import './style.scss';

interface IState {
  branches: IPharmacyBranchListResponseModel[];
  hoveredMarkerIndex?: number;
};

class ContactUs extends HelperComponent<{}, IState> {

  public state: IState = {
    branches: []
  };

  private weeksViewEnum = getViewEnum(WeekDaysEnum);
  private toggleMarker = (index?: number) => this.safeSetState({ hoveredMarkerIndex: index });

  private get hoveredMarkerData() {
    const { hoveredMarkerIndex, branches } = this.state;
    return hoveredMarkerIndex || hoveredMarkerIndex === 0 ? branches[hoveredMarkerIndex] : undefined;
  }
  private WorkingPlan = () => {
    if (!this.hoveredMarkerData) return null;
    return <div className="G-flex G-flex-column">
      {this.hoveredMarkerData.workingPlan?.map((item, index) => <span key={index}>
        {item.endDay ? '-' + Settings.translations[this.weeksViewEnum[item.endDay]] : ''}
        &nbsp;&nbsp;
        {item.isDayOff ? Settings.translations.day_off : `${formatTime(item.startTime)}-${formatTime(item.endTime)}`}
      </span>)}
    </div>
  }
  public getPointOptions = (item:any) => {
    return {
      iconLayout: 'default#image',
      iconImageHref: item.icon.url,
      iconImageSize: [30, 42],
      iconImageOffset: [-5, -38],

    };
  };

  public getPointData = (index:any) => {
    return {
      objectId: index,
      hintContent: "<div class='my-hint'>" +
          "<b>SomeObject</b><br />" +
          "SomeAddress" +
          "</div>",
      balloonContentBody: "placemark <strong>balloon " + index + "</strong>",
      clusterCaption: "placemark <strong>" + index + "</strong>"
    };
  };



  public render() {
    const { hoveredMarkerIndex } = this.state;

    return (
      <section className="G-page P-home-contact-us padMap">
        <h2 className="G-page-title">{Settings.translations.contact_us}</h2>
        <Form />
        <div className="P-bottom">
          <AboutDelivery />
          <AboutBonusCard />
          <AboutMobileApp />
        </div>
      </section>
    );
  }
}

export default ContactUs;
