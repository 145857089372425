import * as React from 'react';
import {Link} from 'react-router-dom';
import Settings from 'platform/services/settings';
import ROUTES from '../../../../../../../platform/constants/routes';
import './style.scss';
import HelperComponent from '../../../../../../../platform/classes/helper-component';
import {IPrescriptionListResponseModel} from '../../../../../../../platform/api/prescription/models/response';
import {formatDate, getViewEnum} from '../../../../../../../platform/services/helper';
import {PrescriptionStatusEnum} from '../../../../../../../platform/api/prescription/constants/enums';
import {statusColorPrescriptionsClassNames} from "../../../orders/constants";

interface IProps {
    data: IPrescriptionListResponseModel[];

    cancel(id: number): void;
};

interface IState {
    smallMedia: boolean;
};

class List extends HelperComponent<IProps, IState> {

    public state: IState = {
        smallMedia: false,
    };

    public componentDidMount() {
        if (window.innerWidth <= 900) {
            this.safeSetState({smallMedia: true})
        }
    }

    private statusViewEnum = getViewEnum(PrescriptionStatusEnum);

    public render() {
        const {data, cancel} = this.props;
        const {smallMedia} = this.state;
        return (<>
            {data && data.map((item: IPrescriptionListResponseModel, index: number) =>
                <>
                    {!smallMedia ?
                        <div className="P-list-item P-list-prescription-list G-my-20" key={index}>
                                <span className="P-prescription-delete"
                                      onClick={() => cancel(item.id)}>{Settings.translations.delete}</span>
                            <div className="P-main-info G-flex G-flex-column">
                                <p className="G-flex G-flex-justify-between G-mb-10">
                                    <span className="G-text-bold">{Settings.translations.date}</span>
                                    <span>{formatDate(item.createdDate)}</span>
                                </p>
                                <p className="G-flex G-flex-justify-between G-mb-10">
                                    <span className="G-text-bold">{Settings.translations.status}</span>
                                    <span
                                        className={statusColorPrescriptionsClassNames[item.status]}>{Settings.translations[this.statusViewEnum[item.status]]}</span>
                                </p>
                                <p className="G-flex G-flex-justify-between G-mb-10">
                                    <span className="G-text-bold">{Settings.translations.medical_institution}</span>
                                    <span>{item.medicalInstitution}</span>
                                </p>
                                <p className="G-flex G-flex-justify-between G-mb-10">
                                    <span className="G-text-bold">{Settings.translations.doctor}</span>
                                    <span>{item.doctorName}</span>
                                </p>
                                <p className="G-flex G-flex-justify-between G-mb-10">
                                    <span className="G-text-bold">{Settings.translations.deciphered_prescription}</span>
                                    <span>{item.name}</span>
                                </p>
                                <p className="G-flex G-flex-justify-end">
                                      <span>
                                        {item.status === PrescriptionStatusEnum.Success && <Link
                                            to={ROUTES.PROFILE.PRESCRIPTIONS.DECIPHERED.replace(':id', String(item.id))}
                                            className="P-see-more-label"
                                        >
                                            {Settings.translations.see_more}
                                        </Link>}
                                      </span>
                                </p>

                            </div>
                        </div>
                        :
                        <div className="P-list-item P-list-prescription-list G-my-20" key={index}>
                            <span className="P-prescription-delete"
                                  onClick={() => cancel(item.id)}>{Settings.translations.delete}</span>
                            <div className={`content-wrapper`}>
                                <div className="P-image-parent">
                                    <div className="P-image" style={{ background: `url('${item.filePath}') center/contain no-repeat` }} />
                                </div>
                                <div className="P-main-info G-flex G-flex-column">
                                    <p className="G-flex G-flex-justify-between G-mb-10">
                                        <span>{item.name}</span>
                                    </p>
                                    <p className="G-flex G-flex-justify-between G-mb-10">
                                    <span
                                        className={statusColorPrescriptionsClassNames[item.status]}>{Settings.translations[this.statusViewEnum[item.status]]}</span>
                                    </p>
                                    <p className="G-flex G-flex-justify-end">
                                      <span>
                                        {item.status === PrescriptionStatusEnum.Success && <Link
                                            to={ROUTES.PROFILE.PRESCRIPTIONS.DECIPHERED.replace(':id', String(item.id))}
                                            className="P-see-more-label"
                                        >
                                            {Settings.translations.see_more}
                                        </Link>}
                                      </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                </>
            )}
        </>);
    }

}


export default List;
