import React from 'react';
import Settings from './settings';
import Storage from 'platform/services/storage';

declare global {
    interface Window {
        dataLayer: any[];
    }
}

function getData(Data:any,EventName:any){
    const gtagEvent = (eventName: string, item: object|null) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(item);
    }
    const UserData = Settings.token && !Settings.guest ? {
        userId: Storage.profile?.id,
        dateOfBirth: Storage.profile?.dateOfBirth,
        email: Storage.profile?.email,
        firstName: Storage.profile?.firstName,
        lastName: Storage.profile?.lastName,
        gender: Storage.profile?.gender,
        phoneNumber: Storage.profile?.phoneNumber,
        photoPath: Storage.profile?.photoPath,
        username: Storage.profile?.username,
    } : null;
    
    const DataEvent = {
        event: EventName,
        ecommerce: {
            user: {
                UserData
            },
            add: {
                products: [
                    {
                        Data,
                    },
                ],
            },
        },
    };
    gtagEvent(EventName, DataEvent);
}
export default getData;