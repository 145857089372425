import { ILoginRequestModel } from "platform/api/auth/models/request";
import { isValidPhone, isValidPassword } from 'platform/services/validator';

export function validateFormBonusCard(this: any, submited?: boolean,backendErrors?:any) {
  let valid = true;
  const errors = {
    bonusCode: false,
    pinCode: false,
  };

  if (submited && !this.bonusCode) {
    errors.bonusCode = true;
    valid = false;
  }

  if (submited && !this.pinCode) {
    errors.pinCode = true;
    valid = false;
  }

  if(submited && backendErrors) {
    errors.pinCode = true;
    valid = false;
    errors.bonusCode = true;
    valid = false;
  }

  return {
    errors,
    valid,
  };
}