import Connection    from '../../services/connection';
import { IResponse } from '../../constants/interfaces';
import { IBonusCardDetailsRequestModel } from './models/request';
import { IBonusCardDetailsResponseModel, IBonusCardDetailsWithHistoryResponseModel, IReferralLinkResponseModel } from './models/response';

class BonusCardController {
 
  private static controller = 'bonusCard';

  public static GetReferralLink = (): Promise<IResponse<IReferralLinkResponseModel>> => {
    const result = Connection.GET({
      action: 'referralLink',
      controller: BonusCardController.controller,
    });

    return result;
  };

  public static GetDetailsWithHistory = (body: IBonusCardDetailsRequestModel): Promise<IResponse<IBonusCardDetailsWithHistoryResponseModel>> => {
    const result = Connection.POST({
      action: 'getDetailsWithHistory',
      controller: BonusCardController.controller,
      body,
    });

    return result;
  };

  public static GetBonusCode = (activeData: any): Promise<IResponse<boolean>> => {
    const result = Connection.GET({
      action: `CheckByPhone?PhoneNumber=${activeData.phoneNumber}&CountryCode=${activeData.countryCode}`,
      controller: BonusCardController.controller
    });

    return result;
  };

  public static GetTransaction = (page:number): any => {
    const Count = 10
    const result = Connection.GET({
      action: `GetTransaction?Count=${Count}&Page=${page}`,
      controller:'BonusCard'
    });

    return result;
  };

  public static GetBonusCardData = (): any => {
    const result = Connection.GET({
      action: `GetDetail`,
      controller:'BonusCard'
    });

    return result;
  };

  public static GetBonusCardReplace = (body: any): Promise<IResponse<any>> => {
    const result = Connection.PUT({
      body,
      action: `Replace`,
      controller:'BonusCard'
    });

    return result;
  };

  public static GetlockBonusCard = (body: any): Promise<IResponse<any>> => {
    const result = Connection.PATCH({
      body,
      action: `Lock`,
      controller:'BonusCard'
    });

    return result;
  };
  
  public static GetUnlockBonusCard = (body: any): Promise<IResponse<any>> => {
    const result = Connection.PATCH({
      body,
      action: `UnLock`,
      controller:'BonusCard'
    });

    return result;
  };
  
  public static GetBonusBalance = (): any => {
    const result = Connection.GET({
      action: `GetBalance`,
      controller:'BonusCard'
    });

    return result;
  };

};

export default BonusCardController;