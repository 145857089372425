
export function sendScrollY(position:any) {
    localStorage.setItem("scrollY",position)
}

export function getScrollY() {
    return localStorage.getItem("scrollY")
}

export function removeScrollY() {
    localStorage.removeItem("scrollY")
}


export function sendfromListPageNumber(pageNumber:any) {
    localStorage.setItem("fromListPageNumber",pageNumber)
}

export function removefromListPageNumber() {
    localStorage.removeItem("fromListPageNumber")
}

export function getfromListPageNumber() {
    return localStorage.getItem("fromListPageNumber")
}


export function sendIsRestoreKey() {
    localStorage.setItem("isRestoreData",'true')
}

export function getIsRestoreKey() {
    return !!localStorage.getItem("isRestoreData")
}

export function removeIsRestoreKey() {
   localStorage.removeItem("isRestoreData")
}

export function sendIsCheckInputKey(key:number) {
    localStorage.setItem(`isCheckInput${key}`,`${true}`)
}

export function getIsCheckInputKey(key:number) {
    return !!localStorage.getItem(`isCheckInput${key}`)
}

export function removeIsCheckInputKey(key:number) {
   localStorage.removeItem(`isCheckInput${key}`)
}