import * as React from 'react';
import './outOfStock.scss';
import Settings from 'platform/services/settings';
import { BasketEmptySTateSvg, BasketOutOfStockStateSvg } from 'components/svg-components';
import MainButton from 'components/Button/mainButton/mainButton';
import Modal from 'components/modal';


const OutOfStockPopUp = React.memo(({onClose}:any) => {

  return (
    <Modal onClose={close} className="P-confirm-modal OutOfStockMainModel">
        <div className='OutOfStockMain'>
            <span className='icon'><BasketOutOfStockStateSvg/></span>
            <span className='P-desc'>{Settings.translations.change_of_stock}</span>
            <span className='P-desc2'>{Settings.translations.see_products_text}</span>
            <MainButton text={Settings.translations.see_products} onClick={onClose}/>
        </div>
    </Modal>

  );
})

export default OutOfStockPopUp;
