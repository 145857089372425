import * as React from 'react';
import ClickOutside from '../../../click-outside';
import HelperComponent from 'platform/classes/helper-component';
import ROUTES from 'platform/constants/routes';
import { Link, NavLink } from 'react-router-dom';
import Settings from 'platform/services/settings';
import './index.scss';



interface IProps {
  onClose(): void;
}

class MobileHeaderPopUP extends HelperComponent<IProps, {}> {

  private navLinkProps = {
    className: 'P-link',
    activeClassName: 'P-active',
    exact: true,
    onClick:() => this.props.onClose()
  };



  public render() {
    const { onClose } = this.props;
    return (
      <div className='main_categori_popUp'>
        <ClickOutside onClickOutside={onClose}>
          <div className="P-header-categories-wrapper">
            <Link to={ROUTES.MN}
              className={`P-link`}
            >
              {Settings.translations.online_pharmacy}
            </Link>
            <NavLink {...this.navLinkProps}to={ROUTES.PHARMACIES}>{Settings.translations.pharmacies}</NavLink>
            <NavLink {...this.navLinkProps} to={ROUTES.BLOG.MAIN}>{Settings.translations.blog}</NavLink>
            <NavLink {...this.navLinkProps} to={"/b"}>{Settings.translations.Dicounts}</NavLink>
            <NavLink {...this.navLinkProps} to={ ROUTES.PROFILE.BONUS_CARD }>{Settings.translations.Bonus_club}</NavLink>
            <NavLink {...this.navLinkProps} to={"/d"}>{"HIPP"}</NavLink>
            <NavLink {...this.navLinkProps} to={"/e"}>{"Natali Gold"}</NavLink>
            <NavLink {...this.navLinkProps} to={"/f"}>{"Best Optics"}</NavLink>
            <NavLink {...this.navLinkProps} to={"/g"}>{"NIKE"}</NavLink>
          </div>
        </ClickOutside>
      </div>
    );
  }
};

export default MobileHeaderPopUP;
