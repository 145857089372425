import React, { useState, useEffect, useRef } from 'react';
import "./styles.scss"
import Settings from 'platform/services/settings';

const RecoveryCodeTimer = ({callBack,startAgain}) => {
  const [seconds, setSeconds] = useState(60);
  const intervalRef = useRef<any>(0);

  useEffect(() => {
    setSeconds(60)
    intervalRef.current = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(intervalRef.current);
  }, [startAgain]);

  useEffect(() => {
    if (seconds === 0) {
      clearInterval(intervalRef.current);
    }
  }, [seconds]);

  return (
    <div className='onMinuteLogic'>
      <span className='text'>{Settings.translations.resend_in}</span>
      {seconds ? <span className='second'>00:{!!(seconds < 10) && 0}{seconds}</span> :
      <button className='resendButton' onClick={callBack}>{Settings.translations.send}</button>}
    </div>
  );
};

export default RecoveryCodeTimer;