import * as React from 'react';
import Settings from 'platform/services/settings';
import { IProductListResponseModel } from 'platform/api/product/models/response';
import { formatPrice } from 'platform/services/helper';
import BasketController from 'platform/api/basket';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import ROUTES from 'platform/constants/routes';
import { Link  } from 'react-router-dom';
import { PromotionTypeEnum } from 'platform/constants/enums';
import GtagData from "../../../../../../../platform/services/gtagData";
import { MdFavorite } from 'react-icons/md';
import { MdOutlineFavoriteBorder } from 'react-icons/md';
import FavoriteController from 'platform/api/favorite';
import { ResizerImgSizeStatic } from 'platform/hooks/useImageSize';
import MainButton from 'components/Button/mainButton/mainButton';
import Storage from 'platform/services/storage';
import './style.scss';
// import './image.scss';


import { useRef } from 'react';
interface IProps {
  data: IProductListResponseModel;
  fetchData:() => void;
  isAddressAdded:boolean;
  isGuwest:any
};

const OriginalListItem = React.memo(({ data:newData, fetchData,isGuwest }: IProps) => {
  const [cartLoading, setCartLoading] = React.useState(false);
  const [data, setData] = React.useState(newData);
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const toggleFavorite = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setData({ ...data, isFavorite: !data.isFavorite });
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(async () => {
      if(isGuwest) {
        window.dispatchEvent(new CustomEvent(DispatcherChannels.toggleAuth));
      } else {
        const result = await FavoriteController.AddOrRemove(data.id);
        GtagData(data,"add_to_wishlist");
        result.success && setData({ ...data, isFavorite: result?.data });
      }
    }, 300);
  }

  const addToCart = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if(isGuwest) {
      window.dispatchEvent(new CustomEvent(DispatcherChannels.toggleAuth));
    } else if(!Storage?.currentAddress) {
      window.dispatchEvent(new Event(DispatcherChannels.openAddress))
    } else {
      setCartLoading(true);
      await BasketController.Change({
        productId: data.id,
        productQuantity: 1,
        isPackage: false,
      });
      GtagData(data,"add_to_cart")
      fetchData()
      window.dispatchEvent(new CustomEvent(DispatcherChannels.CartItemsUpdate));
    }
  }

  const navigateTo = (e:any) => {
    e.preventDefault();
    window.routerHistory.push(ROUTES.PRODUCTS.DETAILS.replace(':id', data.id).replace(':slug', data.slugs[lang]))
  }

  React.useEffect(() => {
    setCartLoading(false);
  },[newData?.isAddedToBasket])

  const isDiscount = !!data?.promotion?.result && data.promotion.promotionType === PromotionTypeEnum.Discount;
  const lang:any = Settings.language


  return (
    <>
      <Link 
        onClick={()=> GtagData(data,"view_item")} 
        to={ROUTES.PRODUCTS.DETAILS.replace(':id', data.id).replace(':slug', data.slugs[lang])} 
        className="P-home-discounted-products-list-item OriginalListItem"
      >
        {!isGuwest && 
        <div className='favourite_item_product' onClick={e => toggleFavorite(e)}>
          {!data.isFavorite ? <MdOutlineFavoriteBorder fontSize={28}/> : <MdFavorite fontSize={28} className='filledIcon'/>}
        </div>}

        {/* <div className="image-parent">
          {!!data.imagePath ? <div className="image" style={{ background: `url('${ResizerImgSizeStatic(data.imagePath)}') center/contain no-repeat` }} /> :
          <div className="image" />}
        </div>
         */}
         
        <div className="P-image-parent">
          {!!data.imagePath ? <div className="P-image" style={{ background: `url('${ResizerImgSizeStatic(data.imagePath)}') center/contain no-repeat` }} /> :
          <div className="P-image" />}
        </div>

        <h3 className="P-product-name">
          {data?.title}
        </h3>

        <div className='price_container'>
          <span className="P-price">{formatPrice(isDiscount ? data.promotion.result : data.price)}</span>
          {isDiscount && <del className="P-without-discount-price">{formatPrice(data.price)}</del>}
        </div>

        <MainButton
          loading={cartLoading}
          onClick={!newData?.havePackage ? addToCart : navigateTo}
          isaddedtobasket={newData?.isAddedToBasket}
        />
      </Link>
    </>
  )
});

export default OriginalListItem;
