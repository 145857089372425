import Settings from "platform/services/settings";
import Storage from "platform/services/storage";
import * as moment from 'moment';
export function generateAddress() {

    let addressOutput = "";
    

    if (Storage?.currentAddress?.building) {
        addressOutput += `${Settings.translations.building} ${Storage?.currentAddress?.building},\n`;
    }

    if (Storage?.currentAddress?.entrance) {
        addressOutput += `${Settings.translations.entrance} ${Storage?.currentAddress?.entrance},\n`;
    }

    if (Storage?.currentAddress?.floor) {
        addressOutput += `${Settings.translations.floor} ${Storage?.currentAddress?.floor},\n`;
    }

    if (Storage?.currentAddress?.apartment) {
        addressOutput += `${Settings.translations.apartment} ${Storage?.currentAddress?.apartment},\n`;
    }

    if (addressOutput.endsWith(",\n")) {
        addressOutput = addressOutput.slice(0, -2);
    }


    return addressOutput;
}

export function getSelectedDate(selectedDay:number) {
    let selectedDate:any;

    if (selectedDay === 1) {
        selectedDate = moment().format('YYYY-MM-DD');
    } else if (selectedDay === 2) {
        selectedDate = moment().add(1, 'days').format('YYYY-MM-DD');
    } else {
        console.log("Invalid SelectedDay value. Please use 1 for today or 2 for tomorrow.")
    }

    return selectedDate;
}