import * as React from 'react';

import LoaderContent from 'components/loader-content';
import Settings from 'platform/services/settings';
import HelperComponent from 'platform/classes/helper-component';
import { IRecoveryRequestModel } from 'platform/api/auth/models/request';
import { validateForm } from './services/helper';
import AuthController from 'platform/api/auth';
import { ModalContentEnum } from '../../constants/enums';
import PasswordInput from "../../../../../components/password-input";
import "../../style.scss"

interface IProps {
  onTypeChange<ActiveData extends object>(type: ModalContentEnum, activeData: ActiveData): void;
  activeData?: object;
};

interface IState {
  submited: boolean;
  submitLoading: boolean;
  form: IRecoveryRequestModel;
};

class NewPassword extends HelperComponent<IProps, IState> {

  public state: IState = {
    submited: false,
    submitLoading: false,
    form: {
      newPassword: '',
      repeatPassword:''
    },
  };

  private get formValidation() {
    const { submited, form } = this.state;
    return validateForm?.call(form, submited);
  }

  private changeField = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { form } = this.state;
    form[e.currentTarget.name] = e.currentTarget.value;
    this.safeSetState({ form })
  }

  private submit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.safeSetState({ submited: true }, async () => {
      this.formValidation.valid && this.safeSetState({ submitLoading: true }, async () => {
        const { form } = this.state;
        const result = await AuthController.ResetPassword(form);
        if (result.data) window.location.reload();
        else this.safeSetState({ submitLoading: false });
      });
    });
  }

  private signIn = () => {
    const { onTypeChange } = this.props;
    onTypeChange(ModalContentEnum.SignIn, { signUp: false });
  };

  public render() {
    const { form, submitLoading } = this.state;

    return <div className='NewPassword_recovery_popUp'>
      <h3 className="G-clr-main G-text-center">{Settings.translations.choose_password}</h3>
      <form className="G-main-form">
        <div className="G-main-form-field recoveryPassword">
          <p className='placeUpHolder'>{Settings.translations.password}</p>
          <PasswordInput
            value={form.newPassword}
            name="newPassword"
            placeholder={Settings.translations.enterPassword}
            className={`G-main-input ${this.formValidation.errors.newPassword ? 'G-invalid-field' : ''}`}
            onChange={this.changeField}
          />

          <p className='placeUpHolder secondHolder'>{Settings.translations.password_confirmation}</p>
          <PasswordInput
            value={form.repeatPassword}
            name="repeatPassword"
            placeholder={Settings.translations.enterPassword}
            className={`G-main-input ${this.formValidation.errors.repeatPassword ? 'G-invalid-field' : ''}`}
            onChange={this.changeField}
          />

        </div>
        <LoaderContent
          className="G-main-button"
          loading={submitLoading}
          onClick={this.submit}
          id={'confirm_btn'}
        >{Settings.translations.SavePassword}</LoaderContent>

        <div className='sign_up_button'>
          <span className="P-sign-in-register-text">{Settings.translations.already_have_acount}</span>
          <button id={'sign_up_btn'} className=" P-sign-in-register G-main-button" onClick={this.signIn}>{Settings.translations.log_in}</button>
        </div>
      </form>
    </div>;
  }
}

export default NewPassword;
