import React, { useState } from "react";
import "./index.scss"; 
import Settings from "platform/services/settings";


interface IProps {
  isChecked: boolean;
  callback: (checked:boolean) => void;
}

function RadioButton({isChecked,callback}:IProps) {

  const handleRadioChange = () => {
    callback(!isChecked);
  };


  return (
    <label className={`label_input`}>
        <span className="radio-button">{Settings.translations.showOnMap}</span>

        <div className={`custom_radio ${isChecked ? "checked" : ""}`}>
            <input
              id="myCheckbox"
              type="checkbox"
              checked={isChecked}
              onChange={handleRadioChange}
            />
        </div>  
    </label>
  );
}

export default RadioButton;