
import ClickOutside from "components/click-outside"
import React, { useEffect, useState } from "react"
import "./style.scss"
import AddressCreateMainPopUp from "./create"
import AddressChangeMainPopUp from "./change"
import { addresOpenEnum } from "components/header"
import UserAddressController from "platform/api/userAddress"
import { RevertStyleForIosMainContent, SetStyleForIosMainContent } from "platform/hooks/setNotScrollledForIos"

export default function AddressMainPopUp({openAddressKey,onClose,HandleOpenAddress,makedefault,defaultValueAdress}) {
    const [addressData,setSddressData] = useState<any>([])

    useEffect(() => {
        (async () => {
            const result = await UserAddressController.GetList();
            setSddressData(result?.data)
            // SetStyleForIosMainContent()
        })()

        return () => {
            RevertStyleForIosMainContent()
        }
    },[])

    return (
        <div className="addressMAinPopUp">
            <ClickOutside className="ClickOutsideAddress" onClickOutside={onClose}>
                { (openAddressKey === addresOpenEnum.create) && <AddressCreateMainPopUp onClose={onClose} HandleOpenAddress={HandleOpenAddress} addressData={addressData}/> }
                { (openAddressKey === addresOpenEnum.change) && <AddressChangeMainPopUp 
                    onClose={onClose} 
                    HandleOpenAddress={HandleOpenAddress}  
                    addressData={addressData} 
                    makedefault={makedefault} 
                    defaultValueAdress={defaultValueAdress} 
                /> } 
            </ClickOutside>
        </div>

    )
}