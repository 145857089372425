import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';

import Markers from '../marker';


const MyMapComponent = withScriptjs(
    withGoogleMap((props:any) => {
        const {pharmacy,centerMap,currentID,callBack} = props

        
        
        return (
            <GoogleMap zoom={centerMap.zoom} center={{ lat:centerMap.addressLat, lng:centerMap.addressLng }} defaultCenter={{ lat:centerMap.addressLat, lng:centerMap.addressLng }} >
                {pharmacy.map((marker:any,index:number) => (<Markers key={index} marker={marker} currentID={currentID} callBack={callBack}/>))}
            </GoogleMap>
        )
    })
);

export default MyMapComponent;
{/* <script src="http://maps.googleapis.com/maps/api/js?key=AIzaSyB_EubnEYSSE5ZbeiGnSs4-ce-YWRj0cHQ"></script> */}
