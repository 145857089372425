import * as React from 'react';
import Slider from 'react-slick';
import HelperPureComponent from 'platform/classes/helper-pure-component';
import ShadowText from 'components/shadow-text';
import Settings from 'platform/services/settings';
import BannerController from 'platform/api/banner';
import { IBannerListResponseModel } from 'platform/api/banner/models/response';
import { BannerTypeEnum } from 'platform/api/banner/constants/enums';
import ROUTES from 'platform/constants/routes';
import ShimmerLoading from 'components/loader-content/shimmerLoading';
import { SamplePrevArrow } from '../arrowComponetn/prevArrow';
import { SampleNextArrow } from '../arrowComponetn/nextArrow';
import './style.scss';
import { ResizerImgSizeDinamic } from 'platform/hooks/useImageSize';

interface IState {
  data: IBannerListResponseModel[];
}

class Carousel extends HelperPureComponent<{}, IState> {

  public state: IState = {
    data: [],
  };

  public componentDidMount() { this.fetchData(); }

  private fetchData = async () => {
    const result = await BannerController.GetList();
    this.safeSetState({ data: result.data });
  }

  private onBannerItemClick = (item: IBannerListResponseModel) => {
    const lang: any = Settings.language;
  
    let url: string;
    if (item.slugs !== null) {
      const urls = {
        [BannerTypeEnum.Blog]: ROUTES.BLOG.DETAILS.replace(':id', item.dataId).replace(':slug', item.slugs[lang]),
        [BannerTypeEnum.News]: ROUTES.NEWS.DETAILS.replace(':id', item.dataId),
        [BannerTypeEnum.Product]: ROUTES.PRODUCTS.DETAILS.replace(':id', item.dataId).replace(':slug', item.slugs[lang]),
        [BannerTypeEnum.Category]: `${ROUTES.PRODUCTS.MAIN}?categoryIds=${item.dataId}`,
        [BannerTypeEnum.Brand]: `${ROUTES.PRODUCTS.MAIN}?brandIds=${item.dataId}`,
        [BannerTypeEnum.ActiveIngredient]: `${ROUTES.PRODUCTS.MAIN}?activeIngredientIds=${item.dataId}`
      };
      url = urls[item.type];
    } else {
      const urls = {
        [BannerTypeEnum.Blog]: ROUTES.BLOG.DETAILS.replace(':id', item.dataId),
        [BannerTypeEnum.News]: ROUTES.NEWS.DETAILS.replace(':id', item.dataId),
        [BannerTypeEnum.Product]: ROUTES.PRODUCTS.DETAILS.replace(':id', item.dataId),
        [BannerTypeEnum.Category]: `${ROUTES.PRODUCTS.MAIN}?categoryIds=${item.dataId}`,
        [BannerTypeEnum.Brand]: `${ROUTES.PRODUCTS.MAIN}?brandIds=${item.dataId}`,
        [BannerTypeEnum.ActiveIngredient]: `${ROUTES.PRODUCTS.MAIN}?activeIngredientIds=${item.dataId}`
      };
      url = urls[item.type];
    }
    
    window.routerHistory.replace(url);
  };

  private settings = {
    responsive: [
      {
        breakpoint: 700,
        settings: {
          swipe:true
        }
      }
    ]
  };

  public render() {
    const { data } = this.state;


    if(!data.length) return <ShimmerLoading colCount={1} className={"ShimmerMainCoverSlider"}/>
    return (
      <div className='G-parent'>
        <Slider
          {...this.settings}
          dots={true}
          autoplay={false}
          autoplaySpeed={5000}
          slidesToShow={1}
          swipe={false}
          slidesToScroll={1}
          // arrows={true}
          nextArrow={<SampleNextArrow/>}
          prevArrow={<SamplePrevArrow/>}
          className="P-home-carousel-slider"
        >
          {data?.map(item =>
            <div key={item.id}>
              <div onClick={() => this.onBannerItemClick(item)}>
                <div className=" P-home-carousel-slide bg-position-bottom" 
                  style={{ background: `url('${ResizerImgSizeDinamic(item.photoPath,[1000,1000])}') center/contain  no-repeat`}}
                >
                  <ShadowText className="P-shadow-text-without-offset">{item.title}</ShadowText>
                </div>
              </div>
            </div>
          )}
        </Slider>
      </div>
    );
  };
};

export default Carousel;