import * as React from 'react';
import HelperPureComponent from 'platform/classes/helper-pure-component';
import './style.scss';
import EmptyState from "../empty-state";
import * as loadingData from 'assets/animations/loading.json';


interface IState {
  show?: boolean;
  isEmpty?:boolean
};


class PageLoader extends HelperPureComponent<IState, IState> {

  public render() {

    return (
      <div className="allPageLoader">
        <span className="loader">{""}</span>
      </div>
    )
  }
}

export default PageLoader;
