import * as React from 'react';
import HelperComponent from 'platform/classes/helper-component';
import Connection from 'platform/services/connection';
import { IPagingResponse } from 'platform/constants/interfaces';
import debounce from 'lodash/debounce';

import './style.scss';
import { getfromListPageNumber } from 'platform/hooks/useStorage';

interface IState {
  pageCount: number;
  selectedPage: number;
  numberValue: number;
};

interface IProps<Data> {
  page?: number;
  count?: boolean;
  interval: number;
  pageCount: number;
  fetchData: (page: number) => any;
  pageChangeListener?: string;
  classNameList?: string;
  loading?: boolean;
};

class ScrollPagination<Data> extends HelperComponent<IProps<Data>, IState> {
    public state: IState = {
        pageCount: 1,
        selectedPage: 1,
        numberValue: 1
    }

    public static defaultProps = {
        interval: 3,
    };

    constructor(props: IProps<Data>) {
        super(props);
        this.debouncedHandleScroll = debounce(this.handleScroll, 200);
    }

    private debouncedHandleScroll: () => void;

    public componentDidMount() {

        const query = new URLSearchParams(window.location.search);
        const { page, pageChangeListener } = this.props;
        if (pageChangeListener) {
            window.addEventListener(pageChangeListener, this.outsidePageChange);
        }
        window.addEventListener("scroll", this.debouncedHandleScroll);
        this.checkSelectedPage();
    }

    public componentWillUnmount() {
        super.componentWillUnmount();
        const { pageChangeListener } = this.props;
        if (pageChangeListener) {
            window.removeEventListener(pageChangeListener, this.outsidePageChange);
        }
        window.removeEventListener("scroll", this.debouncedHandleScroll);
    }

    private outsidePageChange = async (e: CustomEvent) => {
        await this.selectPage(e.detail, true);
        this.checkSelectedPage();
    }

    private checkSelectedPage = () => {
        const { pageCount } = this.state;
        const query = new URLSearchParams(window.location.search);
        const currentPage = Number(query.get('page'));

        if (currentPage > (pageCount || 1)) {
            this.selectPage(pageCount);
            this.safeSetState({ selectedPage: pageCount }); 
        } else {
            this.safeSetState({ selectedPage: currentPage || 1 });
        }
    }

    public getList = async (outside = false) => {
        const { selectedPage, pageCount } = this.state;
        const { fetchData } = this.props;
        if (pageCount > 1 && !outside) {
            Connection.AbortAll();
        }

        const result = await fetchData(selectedPage);
        if (result) {
            this.safeSetState({ pageCount: result?.products?.pageCount });
        }
    }

    public selectPage = async (selectedPage: number, outside = false) => {

        this.safeSetState({ selectedPage }, async () => {
            const query = new URLSearchParams(window.location.search);
            const pageNumberFromDetail = getfromListPageNumber()

            query.set('page', (pageNumberFromDetail || selectedPage || 1).toString());
            window.history.replaceState({ path: window.location.pathname }, '', `?${query}`);
            await this.getList(outside);
        });
    }

    public isScrolledToBottom = () => {
        const scrollY = window.scrollY;
        const windowHeight = window.innerHeight;
        const contentHeight = document.documentElement.scrollHeight;
        return scrollY + windowHeight >= contentHeight - 800;
    }

    private handleScroll = () => {
        const { selectedPage } = this.state;
        const { pageCount, loading } = this.props;
        if (this.isScrolledToBottom() && (selectedPage < pageCount) && !loading) {
            const query = new URLSearchParams(window.location.search);
            const currentPage = Number(query.get('page'));
            
            this.selectPage((currentPage || selectedPage) + 1);
        }
    }

    public render = () => null;
}

export default ScrollPagination;
