import * as React from 'react';
import {Shared} from 'modules';
import ROUTES from 'platform/constants/routes';
import {byRoute} from 'platform/decorators/routes';
import {IProductListResponseModel} from 'platform/api/product/models/response';
import {buildFilters} from './services/helper';
import Filter from './components/filter';
import Connection from 'platform/services/connection';
import SortBox from './components/sort-box';
import HelperComponent from 'platform/classes/helper-component';
import Storage from "../../../../../platform/services/storage";
import {ICategoryTreeResponseModel} from "../../../../../platform/api/category/models/response";
import {IProductFilterRequestModel} from "../../../../../platform/api/product/models/request";
import ScrollPagination from 'components/pagination/scrollPagination';
import ScrollPageLoader from 'components/page-loader/scrollLoader';
import EmptyState from 'components/empty-state';
import { getfromListPageNumber, getIsRestoreKey, getScrollY, removefromListPageNumber, removeIsRestoreKey, removeScrollY, sendIsRestoreKey } from 'platform/hooks/useStorage';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import environment from 'platform/services/environment';
import './style.scss';
import GlobalStorageData from 'platform/services/search';

const pageChangeListener = 'productlistpage';

enum PageSizeEnum {
    size = 16
}

interface IProps {
    onChange(): void;
    initialLoading:any;
};

interface IState {
    loading: boolean;
    sortShow: boolean;
    total: null;
    preferredProductId: number | null,
    data?: IProductListResponseModel[];
    specialProducts?: IProductListResponseModel[];
    categoryTreeData: ICategoryTreeResponseModel[];
    body: IProductFilterRequestModel;
    categoryTitle: string;
    prevCategoryIdRef:any;
    showMobileFilter:boolean
};

@byRoute([ROUTES.PRODUCTS.MAIN])
class List extends HelperComponent<IProps, IState> {
    constructor(props:any) {
        super(props);
        this.fetchDebounceData = this.debounce(this.fetchDebounceData.bind(this), 300);
    }
    private pageLimitSize: number = PageSizeEnum.size;

    public state: any = {
        loading: false,
        total: null,
        preferredProductId: null,
        categoryTreeData: [],
        sortShow: false,
        body: {
            brandIds: [],
            producerIds: [],
            categoryIds: [],
            activeIngredientIds: [],
        },
        AllData:{
            // brandData: [],
            // producerData: [],
            // categoryData: [],
            // activeIngredientData: [],
            productsData:[]
        },
        categoryTitle:'',
        showMobileFilter:false
    };

    public componentDidMount() {
        const positionY:any = getScrollY()
        if(positionY) {
            setTimeout(() => {
                window.scrollTo({top: positionY, left: 0});
                removeScrollY()
            },600)
        }

        window.addEventListener(DispatcherChannels.refetchNewCategoryData, this.eventHandler);
    }

    public componentDidUpdate() {
        if(Storage.categories && !this.state.categoryTitle) {
            this.getCategoryTitle()
        }
    }

    public componentWillUnmount() {
        window.removeEventListener(DispatcherChannels.refetchNewCategoryData, this.eventHandler);
    }

    private eventHandler = () => {
        sendIsRestoreKey()
        this.fetchDebounceData(1)
        this.getCategoryTitle()
    };

    private debounce<T extends (...args: any[]) => void>(func: T, wait: number): (...args: Parameters<T>) => void {
        let timeout: ReturnType<typeof setTimeout>;
        return function (this: any, ...args: Parameters<T>) {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    }

    private filterChange = () => {
        this.safeSetState({data: []});
        window.dispatchEvent(new CustomEvent(pageChangeListener, {detail: 1}));
    }

    private fetchDebounceData = (pageNumber: any): any => this.fetchData(pageNumber);
    private abortController: AbortController | null = null;

    private fetchData = async (pageNumber: number) => {
        if (this.abortController) {
            this.abortController.abort();
        }
    
        this.abortController = new AbortController();
        const abortsignal = this.abortController.signal;
        const query = new URLSearchParams(window.location.search);


        try {
            this.safeSetState({loading: true});
            const body = {
                ...buildFilters(),
                pageNumber,
                pageSize: this.pageLimitSize,
                hasProduct: true,
                paging: {
                    count: this.pageLimitSize,
                    page:pageNumber 
                }
            };
    
            const HEADERS = Connection.createHeaders();
            const response = await fetch(`${environment.BASE_URL}api/product/list`, {
                method: 'POST',
                headers: HEADERS,
                body: JSON.stringify(body),
                signal: abortsignal 
            });
    
            const result = await response.json();

            const preferredProductId = query.get('preferredProductId');
            const stateData: any = this.state.AllData.productsData;
    
            if (!result.aborted && result.data) {
                const data = getIsRestoreKey() ? result.data.products.data : stateData.concat(result.data.products.data);
                const uniqueMap = new Map();
                
                data.forEach((item: any) => { uniqueMap.set(item.id, item) });
                let uniqueArray = Array.from(uniqueMap.values());
                const pageNumberFromDetail = getfromListPageNumber()

                if(!pageNumberFromDetail) {
                    GlobalStorageData.fillProductList(uniqueArray)
                } 

                if(pageNumberFromDetail) {
                    uniqueArray = GlobalStorageData.productList
                    removefromListPageNumber()
                }

                this.safeSetState({
                    AllData: {
                        productsData: uniqueArray,
                        pageCount: result.data.products.pageCount,
                    },
                    data: stateData.concat(result.data.data),
                    total: result.data.totalCount,
                    preferredProductId,
                    loading: false
                });
            }
    
            removeIsRestoreKey();
            return result.data;
    
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Fetch aborted');
            } else {
                console.error('Fetch error:', error);
            }
            this.safeSetState({loading: false});
            return null;
        }
    };

    private getCategoryTitle = async () => {
        const query = new URLSearchParams(window.location.search);
        const categoryId = query.get('categoryIds');
        
        const categories:any = Storage.categories;
        const result = categories?.find((item:any) => item.id === Number(categoryId));
        this.setState({categoryTitle:result?.name || null})
    }

    private openCloseFilter = () => {
        const { showMobileFilter } = this.state;
        if(showMobileFilter) {
            document.body.style.overflow = 'initial';
        } else {
            document.body.style.overflow = 'hidden';
        }
        this.setState({showMobileFilter:!showMobileFilter})
    }

    public render() {
        const { total, loading, preferredProductId, categoryTitle, AllData, showMobileFilter } = this.state;
        const { productsData, pageCount } = AllData

        return (
            <section className="G-page P-products-list-page">
                <Filter openFilter={this.openCloseFilter} showMobileFilter={showMobileFilter} FilterData={AllData} onChange={this.filterChange} productLoading={loading}/>
                <div className="P-list-wrapper newWraper">
                    <SortBox openFilter={this.openCloseFilter} onChange={this.filterChange} categoryTitle={categoryTitle}/>

                    {productsData?.map((item:any,index:number) => <Shared.Products.SimilarListItem key={index} specialProductId={preferredProductId} data={item} fromList={true}/>)}
                    
                    <ScrollPagination<IProductListResponseModel> 
                        classNameList="P-product-page" 
                        pageChangeListener={pageChangeListener}
                        fetchData={this.fetchDebounceData}
                        loading={loading}
                        pageCount={pageCount}
                    />
                    
                    {loading ? <ScrollPageLoader /> : total === 0 && <EmptyState />}
                </div>
            </section>
        );
    }
};
export default List;
