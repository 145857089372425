
class GlobalStorageData {
    public static changeText = (text:any) => {
        GlobalStorageData.text = text;
    }

    public static fillProductList = (list:any) => {
        GlobalStorageData.productList = list;
    }

    public static text: any;
    public static productList: any;

}
  
export default GlobalStorageData;