// Function to add the class
export function SetStyleForIosMainContent() {
    const mainElement = document.querySelector('#P-content.MainContent');
  
    if (mainElement) {
      mainElement.classList.add('MainContentIosNavBarCloseUseHeight');
    }
}

// Function to remove the class
export function RevertStyleForIosMainContent() {
    const mainElement = document.querySelector('#P-content.MainContent');
  
    if (mainElement) {
      mainElement.classList.remove('MainContentIosNavBarCloseUseHeight');
    }
}
