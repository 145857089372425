import * as React from 'react';
import {withRouter, RouteComponentProps, Link} from 'react-router-dom';
import generic from 'platform/decorators/generic';
import ROUTES from 'platform/constants/routes';
import { byRoute } from 'platform/decorators/routes';
import HelperComponent from 'platform/classes/helper-component';
import PageLoader from 'components/page-loader';
import './style.scss';
import VacancyController from "../../../../../platform/api/vacancy";
import {IVacancyListResponseModel} from "../../../../../platform/api/vacancy/models/response";
import Settings from "../../../../../platform/services/settings";
import Form from "../../components/list-item/components/form";

interface IRouteParams { id:string,slug: string };

interface IState {
  data?: IVacancyListResponseModel;
};

@generic<RouteComponentProps<IRouteParams>>(withRouter)
@byRoute(ROUTES.VACANCIES.DETAILS)
class Details extends HelperComponent<RouteComponentProps<IRouteParams>, IState> {
  public state: IState = {};

  public componentDidMount() { this.fetchData(); }

  private goBack = () => window.routerHistory.goBack();

  private fetchData = async () => {
    const { slug } = this.props.match.params;
    const { id } = this.props.match.params;
    const result = await VacancyController.GetDetails(id,slug);

      this.safeSetState({ data: result.data });
  }

  public render() {
    const { data } = this.state;
    return data ? (
        <>
          <ul className="Breadcrumbs">
              <li className='fromRoutes'> <Link to={ROUTES.MN}> {Settings.translations.home}</Link></li>
              <li className='fromRoutes'><Link to={ROUTES.VACANCIES.MAIN}> {Settings.translations.vacancies}</Link> </li>
              <li className='activeRoute'> { data.name}</li>
          </ul>
          <section className="G-page P-vacancies-details-page">
            {window.routerHistory.length > 2 && <i className="G-back-icon icon-Group-5529" onClick={this.goBack} />}
            <div className="P-content">
                <h2 className="P-name">{data.name}</h2>
              <p className="P-description" dangerouslySetInnerHTML={{ __html: data.description}} />
            </div>
          </section>
            <div className={`P-vacancies-list-item parent-wrapper`} >
            <h2 className="P-apply-online">{Settings.translations.apply_online}</h2>

            <Form data={data} />
            </div>
        </>

    ) : <PageLoader />;
  }
};

export default Details;
