import * as React from 'react';
import ClickOutside from '../../../click-outside';
import HelperComponent from 'platform/classes/helper-component';
import { ICategoryListResponseModel } from 'platform/api/category/models/response';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import ROUTES from 'platform/constants/routes';
import Storage from 'platform/services/storage';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import './style.scss';
import { ProductSortEnum } from 'platform/api/product/constants/enums';


interface IProps {
  onClose(): void;
}

interface IState {
  lists: ICategoryListResponseModel[][];
};

class Categories extends HelperComponent<IProps, {}> {

  public state: IState = {
    lists: [ Storage.categories ]
  };

  private clickItem = (e: React.SyntheticEvent, category: ICategoryListResponseModel) => {
    const { onClose } = this.props;

    setTimeout(() => {
      window.dispatchEvent(new Event(DispatcherChannels.refetchNewCategoryData));
      onClose();
    }, 0);
  }

  public render() {
    const { lists } = this.state;
    const { onClose } = this.props;
    
    if(!lists[0]) return null
    return (
      <div className='main_categori_popUp'>
        <ClickOutside onClickOutside={onClose}>
          <div className="P-header-categories-wrapper">
            {lists?.map((item, index) => <div key={index} className="P-list">
              {item?.map(sub => (
                <Link
                  key={sub.id}
                  to={!sub.id ? `${ROUTES.PRODUCTS.MAIN}?sortBy=${ProductSortEnum.PriceHighToLow}&hasDiscount=true` : `${ROUTES.PRODUCTS.MAIN}?categoryIds=${sub.id}&sortBy=${ProductSortEnum.PriceHighToLow}`}
                  className={''}
                  onClick={e => this.clickItem(e, sub)}
                >
                  <h3 className='title_popup' title={sub.name}>{sub.name}</h3>
                  {sub.hasChildren && <i className="icon-Group-5513" />}
                </Link>
              ))}
            </div>)}
          </div>
        </ClickOutside>
      </div>
    );
  }
};

export default Categories;
