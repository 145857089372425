import {OrderStatusEnum, PrescriptionsEnum} from 'platform/api/order/constants/enums';

export const statusColorClassNames = {
  [OrderStatusEnum.Pending]: 'G-clr-orange-new',
  [OrderStatusEnum.Scheduled]: 'G-clr-orange-new',
  [OrderStatusEnum.Collecting]: 'G-clr-orange-new',
  [OrderStatusEnum.Delivering]: 'G-clr-light-gray-new',
  [OrderStatusEnum.Finished]: 'G-clr-main-new',
  [OrderStatusEnum.Canceled]: 'G-clr-red-new',
};

export const statusColorClassNamesNew = {
  [OrderStatusEnum.Pending]: 'G-clr-orange-new',
  [OrderStatusEnum.Scheduled]: 'G-clr-orange-new',
  [OrderStatusEnum.Collecting]: 'G-clr-orange-new',
  [OrderStatusEnum.Delivering]: 'G-clr-light-gray-new',
  [OrderStatusEnum.Finished]: 'G-clr-main-new',
  [OrderStatusEnum.Canceled]: 'G-clr-red-new',
};

export const statusColorPrescriptionsClassNames = {
  [PrescriptionsEnum.Delivered]: 'G-clr-green',
  [PrescriptionsEnum.Failed]: 'G-clr-red',
  [PrescriptionsEnum.Pending]: 'G-clr-orange',
};


