import React, { useEffect, useRef, useState } from "react";
import Inputs_TextField from "./textField";
import environment from "platform/services/environment";
import Settings from "platform/services/settings";
import ClickOutside from "components/click-outside";



export default function AutoComplate({onPlaceSelect,addressError,defaultValue,fieldTitle}:any) {
    const [cityName, setCityName] = useState<any>("");
    const [validName, setValidName] = useState<any>("");
    const [options, setOptions] = useState<any>([])


    useEffect(() => {
        if(!cityName) {setValidName("");onPlaceSelect("");}
        (async () => {
            await fetch(`${environment.BASE_URL}api/Geographic?address=${cityName}`)
            .then((res) => res.json()).then((data) => {
                  if(data?.success) {
                    setOptions(data?.data)
                  } else {
                    setOptions([])
                  }
            })
        })();
    },[cityName])

    function onClose() {
        setCityName(validName)
        setOptions([])
    }

    return (
        <ClickOutside onClickOutside={onClose}>
            <Inputs_TextField name={fieldTitle}>
                <input 
                    placeholder={`${Settings.translations.Write_input} ${Settings.translations.address.toLowerCase()}`} 
                    className={`autoComplate ${addressError ? "addborder" : ""}`}
                    value={cityName || defaultValue}
                    onChange={(event) => {
                        setCityName(event.target.value)
                    }}
                />
                {(cityName !== validName && options?.length > 0) && <div className="optiionAutoComplete"
                    onClick={(e) => {e.stopPropagation()}}
                >
                    {options?.map((val:any,index:number) => {
                        return <p key={index} className="textOption"
                            onClick={(e) => {
                                e.stopPropagation
                                setCityName(val)
                                setValidName(val)
                                onPlaceSelect(val)
                                setOptions([])
                            }}
                        >{val}</p>
                    })}
                </div>}
            </Inputs_TextField>
        </ClickOutside>

    )
}