import * as React from 'react';
import './style.scss';
import Settings from 'platform/services/settings';
import {TransactionEmptySTateSvg } from 'components/svg-components';


const EmptyStateTransaction= React.memo(() => {

  return (
    <div className='EmptyStateTransaction'>
      <TransactionEmptySTateSvg/>
      <span className='P-desc'>{Settings.translations.EmptyTransaction}</span>
    </div>
  );
})

export default EmptyStateTransaction;
