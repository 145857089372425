import * as React from 'react';
import HelperComponent from 'platform/classes/helper-component';
import {  Marker } from 'react-google-maps';

interface IProps {
    marker: any;
    currentID:number,
    callBack:any
}

interface IState {
    currentId:number
}

class Markers extends HelperComponent<IProps, IState> {
  public state: IState = {
    currentId:NaN
  };

  public render() {
    const { marker,callBack } = this.props;
    const { id,addressLat,addressLng } = marker 
    
    return (
        <Marker
            onMouseOver={() => this.setState({currentId:id})}
            onMouseOut={() => this.setState({currentId:NaN})}
            onClick={() => callBack({addressLat,addressLng,currentID:id})}
            key={id} 
            position={{ lat: addressLat, lng: addressLng }} 
        />
    );
  }
};

export default Markers;