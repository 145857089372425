import * as React from 'react';

import HelperComponent from 'platform/classes/helper-component';
import Storage from 'platform/services/storage';
import { getMediaPath } from 'platform/services/helper';
import { Link } from 'react-router-dom';
import ROUTES from 'platform/constants/routes';
import CameraImage from 'assets/images/camera.png';
import UserController from 'platform/api/user';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import Broadcast from 'platform/services/broadcast';
import "./personImg.scss"
import { ResizerImgSizeDinamic } from 'platform/hooks/useImageSize';

interface IState {
  userImg:any,
  onloadImg:boolean
};

class PersonImage extends HelperComponent<any, IState> {

  public state: IState = {
    onloadImg:false,
    userImg:"",
  };
  private uploadInput = React.createRef<HTMLInputElement>();

  private openUpload = () => {
    const { current } = this.uploadInput;
    current && current.click();
  }

  private uploadEnd = async (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;
    const { CallbackImg } = this.props;

    if (files && files[0]) {
      CallbackImg && CallbackImg(files[0])
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({ userImg: reader.result });
      };
      reader.readAsDataURL(files[0]);
    }
  }


  public render() {
    const { userImg } = this.state
    
    return (
        <div className="P-main-info_person">
          <div className="P-image">
            <div className={`P-upload-camera`} onClick={this.openUpload}>
              {userImg ? 
                <img className='P-image' style={{opacity:1}} src={userImg} alt="camera" /> : 
                Storage?.profile?.photoPath ? 
                <img className='P-image' style={{opacity:1}} src={ResizerImgSizeDinamic(`${Storage?.profile?.photoPath}`,[200,200])} alt="camera" /> :
                <img className='P-image' style={{opacity:1}} src={CameraImage} alt="camera" />
              }
              <input ref={this.uploadInput} type="file" accept="image/*" onChange={this.uploadEnd} />
            </div>
          </div>
        </div> 
    );
  }
}

export default PersonImage;
