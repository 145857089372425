



export function validateFormCreate(form: any, submited?: boolean) {
  let valid = true;
  
  const errors = {
    building: false,
    addressText: false,
  };

  if (submited && !form.building.trim()) {
    errors.building = true;
    valid = false;
  }

  if (submited && (!form.addressText)) {
    errors.addressText = true;
    valid = false;
  }
      
  return {
    errors,
    valid,
  };
}

export function isCreate(form: any) {
  let valid = true;
  if (!form.building.trim() || !form.addressText) {
    valid = false;
  }
        
  return {
    valid,
  };
}