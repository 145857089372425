
import React, { useEffect, useState } from "react"
import "./style.scss"
import Settings from "platform/services/settings"
import MainButton from "components/Button/mainButton/mainButton"
import List from "modules/pages/profile/pages/addresses/components/list"
import UserAddressController from "platform/api/userAddress"
import { addresOpenEnum } from "components/header"
import DispatcherChannels from "platform/constants/dispatcher-channels"

export default function AddressChangeMainPopUp({onClose,HandleOpenAddress,addressData,makedefault,defaultValueAdress}) {
    const [isActiveId,setIsActiveId] = useState(NaN)
    useEffect(() => { setIsActiveId(defaultValueAdress.id)},[])
       
    const makeDefault = async (id: number) => {
        const result = await UserAddressController.MakeDefault(id);
        window.dispatchEvent(new CustomEvent(DispatcherChannels.CartUpdate));
        window.dispatchEvent(new CustomEvent(DispatcherChannels.checkAddress));


        if(result.success) {
            const defaultValue = addressData.find((val:any) => val.id === id)
            makedefault({
                value:defaultValue.addressText,
                id:defaultValue.id
            })
            onClose()
        }
    }
    
    return (
        <div className="ChangeAddressPopUp">
            <div className="chengeTitle">
                <p className='itemTitle'>{Settings.translations.choose_addresstitle}</p>
                <p className='addAddress' onClick={() => HandleOpenAddress(addresOpenEnum.create)}>+ {Settings.translations.add_address}</p>
            </div>

            <div className="listAddress">
                <List 
                    isActiveId={isActiveId}
                    onRowClick={(item:any) => setIsActiveId(item.id)}
                    data={addressData}
                />
            </div>
            
            <div className="buttonField">
                <MainButton text={Settings.translations.cancel} onClick={onClose} />
                <MainButton text={Settings.translations.save}  onClick={() => makeDefault(isActiveId)}/>
            </div>
        </div>
    )
}