import * as React from 'react';

import ROUTES from 'platform/constants/routes';
import {byRoute} from 'platform/decorators/routes';
import HelperPureComponent from 'platform/classes/helper-component';
import './style.scss';
import ProductController from "../../../platform/api/product";
import PageLoader from "../../../components/page-loader";
import {IProductSalesResponseModel} from "../../../platform/api/product/models/response";
import Pagination from "../../../components/pagination";
import {IPagingResponse} from "../../../platform/constants/interfaces";

interface IState {
    data?: IPagingResponse<IProductSalesResponseModel>;
    kind:number,
    pageSize:number
}

@byRoute([ROUTES.PRODUCT_SALES])
class ProductSales extends HelperPureComponent<{}, IState> {
    public state: IState = {
        kind: Number(window.location.pathname.split("/").pop()) || 1,
        pageSize:100
    };
    private fetchData = async (pageNumber) => {
        const body = {
            pageNumber,
            pageSize: this.state.pageSize,
        };
        const result = await ProductController.GetProductSales(this.state.kind,body);
        result.data.list.length ? this.safeSetState({data: result.data}) : [];
        return result.data
    }
    public componentDidMount() { this.fetchData(1) }

    public render() {
        const {data,pageSize,kind} = this.state;
        const urlString = window.location.search;
        const page=(urlString.split('?page=')[1]) ? +urlString.split('?page=')[1] : 1 ;
        return (
            <>
                {data && data.list.length > 0 ? <>
                    <section className="G-page black-friday">
                        <table className="table">
                            <thead>
                            <tr>
                                <th/>
                                <th>Ապրանքի անվանում</th>
                                {(kind !== 2) ? <>
                                    <th>Ապրանքի գին,ՀՀ դրամ</th>
                                </> : ''}
                                {(kind === 4 || kind === 5) ? <>
                                    <th>Դեղատնային ցանցում ապրանքի միջինացված գին,ՀՀ դրամ</th>
                                </> : ''}
                                <th>Դեղատնային ցանցում զեղչ %</th>
                                {(kind === 4 || kind === 5) ? <>
                                    <th>Դեղատնային ցանցում զեղչված արժեք միջին գնից, ՀՀ դրամ</th>
                                </> : ''}
                                {(kind !== 2 && kind !== 4 && kind !== 5) ? <>
                                    <th>Դեղատնային ցանցում զեղչված արժեք, ՀՀ դրամ</th>
                                </> : ''}
                                {(kind === 4 || kind === 5) ? <>
                                    <th>Օնլայն դեղատանը ապրանքի միջինացված գին, ՀՀ դրամ</th>
                                </> : ''}
                                <th>Օնլայն դեղատան զեղչ %</th>
                                {(kind !== 2 && kind !== 4 && kind !== 5) ? <>
                                    <th>Օնլայն դեղատանը զեղչված արժեք, ՀՀ դրամ</th>
                                </> : ''}
                                {(kind === 4 || kind === 5) ? <>
                                    <th>Օնլայն դեղատանը զեղված արժեք միջին գնից, ՀՀ դրամ</th>
                                </> : ''}
                            </tr>
                            </thead>
                            <tbody>
                            {data.list.map((item, index) =>
                                <tr key={index}>
                                    <td>{(page !== 1) ? (index + 1 + ((page - 1) * pageSize)) : index + 1}</td>
                                    <td>{item.name}</td>
                                    {(kind !== 2) ? <>
                                        <td>{item.price}</td>
                                    </> : ''}
                                    {(kind === 4 || kind === 5) ? <>
                                        <td>{item.avgPriceInPharmacy.toFixed(0)}</td>
                                    </> : ''}
                                    <td>{item.discountInPharmacy.toFixed(0)}%</td>
                                    {(kind === 4 || kind === 5) ? <>
                                        <td>{item.discountedPriceInPharmacy.toFixed(0)}</td>
                                    </> : ''}
                                    {(kind !== 2 && kind !== 4 && kind !== 5) ? <>
                                        <td>{item.discountedPriceInPharmacy.toFixed(0)}</td>
                                    </> : ''}
                                    {(kind === 4 || kind === 5) ? <>
                                        <td>{item.avgPriceInOnlinePharmacy.toFixed(0)}</td>
                                    </> : ''}
                                    <td>{item.discountInOnlinePharmacy.toFixed(0)}%</td>
                                    {(kind !== 2 && kind !== 4 && kind !== 5) ? <>
                                        <td>{item.discountedPriceInOnlinePharmacy.toFixed(0)}</td>
                                    </> : ''}
                                    {(kind === 4 || kind === 5) ? <>
                                        <td>{item.discountedPriceInOnlinePharmacy.toFixed(0)}</td>
                                    </> : ''}
                                </tr>
                            )}
                            </tbody>
                        </table>
                            <Pagination<IProductSalesResponseModel> fetchData={this.fetchData}/>
                    </section>
                    </> : <PageLoader/>}
             </>
        );
    }
}

export default ProductSales;