import Connection    from '../../services/connection';
import { IPagingResponse, IResponse } from '../../constants/interfaces';
import { IPharmacyBranchListResponseModel } from './models/response';
import { IPharmacyBranchListRequestModel } from './models/request';
import environment from 'platform/services/environment';

class PharmacyBranchController {
 
  private static controller = 'pharmacyBranch';

  public static GetForSelection = async () => {
    const result = await fetch(`${environment.BASE_URL}api/Place/City`);
    return result.json();
  };

  public static GetForPharmacy = (cityId: number): Promise<IResponse<IPagingResponse<IPharmacyBranchListResponseModel>>> => {
    const result = Connection.GET({
      action: `ListByCityId?cityId=${cityId}`,
      controller: PharmacyBranchController.controller,
    });

    return result;
  };
  
  public static GetByRegion = (regionId: number): Promise<IResponse<IPharmacyBranchListResponseModel[]>> => {
    const result = Connection.GET({
      action: `region/${regionId}`,
      controller: PharmacyBranchController.controller,
    });

    return result;
  };

  public static GetByCity = (cityId: number): Promise<IResponse<IPharmacyBranchListResponseModel[]>> => {
    const result = Connection.GET({
      action: `regionByCity/${cityId}`,
      controller: PharmacyBranchController.controller,
    });

    return result;
  };
};

export default PharmacyBranchController;