export enum OrderStatusEnum {
  Pending,
  Scheduled,
  Collecting,
  Delivering,
  Canceled,
  Finished
};

export enum PrescriptionsEnum {
  Pending = 1,
  Delivered,
  Failed
};

export enum OrderDeliveryTypeEnum {
  Delivery = 1,
  Pickup,
};

export enum OrderDeliveryTimeTypeEnum {
  Asap = 1,
  DeliveryDate,
};
