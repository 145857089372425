import * as React from 'react';

import HelperComponent from 'platform/classes/helper-component';
import { byPrivateRoute } from 'platform/decorators/routes';
import ROUTES from 'platform/constants/routes';
import Layout from '../../components/layout';
import DetailsForm from './components/details-form';
import PasswordForm from './components/password-form';
import Settings from 'platform/services/settings';
import PersonImage from './components/personIMage';

@byPrivateRoute(ROUTES.PROFILE.MAIN)
class PersonalInfo extends HelperComponent<{}, {}> {

  public state: any = {
    selectedFile:""
  };

  public render() {
    const { selectedFile } = this.state

    return (
      <Layout>
        <div className="G-flex G-flex-wrap G-flex-align-start PersonalInfoPage">
          <h2 className="G-clr-main G-text-left G-full-width G-h2-calc">{Settings.translations.personal_data}</h2>
          <PersonImage CallbackImg={(isfile:any) => {this.setState({selectedFile:isfile})}}/>
          <DetailsForm file={selectedFile}/>
          {/* <h2 className="G-clr-main G-text-left G-mb-30 G-mt-30 G-full-width G-h2-calc">{Settings.translations.change_password}</h2> */}
          {/* <PasswordForm /> */}
        </div>
      </Layout>
    );
  }
}

export default PersonalInfo;
