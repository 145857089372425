import * as React from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';

import HelperComponent from 'platform/classes/helper-component';
import { byPrivateRoute } from 'platform/decorators/routes';
import ROUTES from 'platform/constants/routes';
import Layout from '../../../../components/layout';
import LoaderContent from 'components/loader-content';
import Settings from 'platform/services/settings';
import { validateFormNew } from './services/helper';
import { IUserAddressModifyRequestModel } from 'platform/api/userAddress/models/request';
import UserAddressController from 'platform/api/userAddress';
import generic from 'platform/decorators/generic';
import { IYandexPlace } from 'platform/constants/interfaces';
import { trimForm } from 'platform/services/helper';
import { MdOutlineArrowBack } from 'react-icons/md';
import './style.scss';
import AutoComplate from 'components/inputAutocomplate';
import { SVGComponentBackToIcon } from 'components/svg-components';

interface IState {
  form: any;
  submited: boolean;
  submitLoading: boolean;
};

interface IRouteParams {
  id: string;
};

@generic<RouteComponentProps<IRouteParams>>(withRouter)
@byPrivateRoute([ROUTES.PROFILE.ADDRESSES.CREATE, ROUTES.PROFILE.ADDRESSES.UPDATE])
class Modify extends HelperComponent<RouteComponentProps<IRouteParams>, IState> {

  public state: IState = {
    submited: false,
    submitLoading: false,
    form: {
      building: '',
      addressText: '',
      name:"",
      entrance:"",
      apartment:"",
      floor:""
    },
  };

  public componentDidMount() {
    this.fetchData();
  }

  private fetchData = async () => {
    const { id } = this.props.match.params;
    if (id) {
      const result = await UserAddressController.GetDetails(+id);
      const { form } = this.state;
      form.name = result.data.name;
      form.building = result.data.building;
      form.apartment = result.data.apartment;
      form.entrance = result.data.entrance;
      form.floor = result.data.floor;
      form.addressText = result.data.addressText;
      this.safeSetState({ form });
    }
  }

  private get formValidation() {
    const { submited, form } = this.state;
    return validateFormNew?.call(form, submited);
  }

  private goBack = () => window.routerHistory.goBack();

  private changeField = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { form } = this.state;
    form[e.currentTarget.name] = e.currentTarget.value;
    this.safeSetState({ form });
  }

  private submit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.safeSetState({ submited: true }, async () => {
      this.formValidation.valid && this.safeSetState({ submitLoading: true }, async () => {
        const { id } = this.props.match.params;
        const { form } = this.state;
        const trimedForm = trimForm<IUserAddressModifyRequestModel>(form);
        const result = id ? await UserAddressController.Update(+id, trimedForm) : await UserAddressController.Create(trimedForm);
        if (result.data) window.routerHistory.push(ROUTES.PROFILE.ADDRESSES.MAIN);
        else this.safeSetState({ submitLoading: false });
      });
    });
  }

  private onAddressSelect = (value: IYandexPlace) => {
    const { form } = this.state;
    form.addressText = value;
    this.safeSetState({ form });
  }

  public render() {
    const { form, submitLoading } = this.state;
    
    return (
      <Layout>
        <div className='title_cont'>
          <div className='backToMenuBonus' onClick={() => this.goBack()}>
            <SVGComponentBackToIcon/>
            <p className='menuTitle'>{Settings.translations.back_menu}</p>
          </div>
          <p className='itemTitle'>{this.props.match.params.id ? Settings.translations.edit_address : Settings.translations.add_new_address}</p>
        </div>

        {/* validateFormCreate(form,submitLoading).errors.addressText */}
        <div className="G-flex G-flex-wrap P-profile-addresses-modify">
          <form className="G-main-form P-form-block G-mr-20 G-flex-justify-end">
            <div className="G-flex G-flex-justify-between P-address-form">
              <div className="G-main-form-field G-mr-20">
                <p className="G-input-top-label">{Settings.translations.name}</p>
                <input
                  placeholder={`${Settings.translations.Write_input} ${Settings.translations.name.toLowerCase()}`}
                  name="name"
                  value={form.name}
                  className={`G-main-input`}
                  onChange={this.changeField}
                />
              </div>
              <div className="G-main-form-field">
                <AutoComplate 
                  onPlaceSelect={this.onAddressSelect}
                  addressError={this.formValidation.errors.addressText}
                  defaultValue={form.addressText}
                  fieldTitle={`${Settings.translations.address} *`}
                />
              </div>
            </div>

            <div className="addrerss_newMain">
              <div className="G-main-form-field ">
                <p className="G-input-top-label">{`${Settings.translations.building} *`}</p>
                <input
                  placeholder={`${Settings.translations.Write_input} ${Settings.translations.building.toLowerCase()}`}
                  name="building"
                  value={form.building || ''}
                  className={`G-main-input ${this.formValidation.errors.building ? 'G-invalid-field' : ''}`}
                  onChange={this.changeField}
                />
              </div>
              <div className="G-main-form-field">
                <p className="G-input-top-label">{Settings.translations.entrance}</p>
                <input
                  placeholder={`${Settings.translations.Write_input} ${Settings.translations.entrance.toLowerCase()}`}
                  name="entrance"
                  value={form.entrance || ''}
                  className="G-main-input"
                  onChange={this.changeField}
                />
              </div>
              <div className="G-main-form-field">
                <p className="G-input-top-label">{Settings.translations.floor}</p>
                <input
                  placeholder={`${Settings.translations.Write_input} ${Settings.translations.floor.toLowerCase()}`}
                  name="floor"
                  value={form.floor || ''}
                  className="G-main-input"
                  onChange={this.changeField}
                />
              </div>
              <div className="G-main-form-field">
                <p className="G-input-top-label">{Settings.translations.apartment}</p>
                <input
                  placeholder={`${Settings.translations.Write_input} ${Settings.translations.apartment.toLowerCase()}`}
                  name="apartment"
                  value={form.apartment || ''}
                  className="G-main-input"
                  onChange={this.changeField}
                />
              </div>
            </div>

            <div className='button_field'>
              <LoaderContent
                className="G-main-button G-mt-1"
                loading={submitLoading}
                onClick={this.submit}
                id={'save_btn'}
              >{Settings.translations.save}</LoaderContent>
            </div>
          </form>
        </div>
      </Layout>
    );
  }
}

export default Modify;
