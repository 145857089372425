import fetch from 'node-fetch';
import environment from './environment';
import { LanguageEnum } from '../constants/enums';

class ConnectionServer {
  // //? Request Helper for server
  private static queryFromObject = (obj: object): string => {
    const str: string[] = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p) && (obj[p] !== null && obj[p] !== undefined && obj[p] !== '')) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
    return str.join("&");
  }
  
  public static ServerRequest = async ({ method, controller, action, body, query }: {
    method: string;
    controller: string;
    action: string;
    body?: any;
    query: { [key: string]: any },
  }) => {
    const onlyQuery = !action && query;
    const response = await fetch(`${environment.BASE_URL}api/${controller}${!onlyQuery ? '/' : ''}${action}${query ? `?${ConnectionServer.queryFromObject(query)}` : ''}`, {
      body,
      headers: { language: LanguageEnum.English.toString() },
      method,
    });
    return response.ok ? response.json() : {};
  }
}

export default ConnectionServer;