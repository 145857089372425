import * as React from 'react';
import './style.scss';
import Lottie from 'react-lottie';
import Settings from 'platform/services/settings';

interface IProps {
  text?: string;
};



const EmptyState = React.memo(({text}:IProps) => {

  return (
    <div className='P-no-data'>
      <span className='P-desc'>{text|| Settings.translations.no_search_result}</span>
    </div>
  );
})

export default EmptyState;
