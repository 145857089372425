import React from "react";
import { SVGComponentPrevArrowIcon } from "components/svg-components";

export function SamplePrevArrow(props:any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "green" }}
        onClick={onClick}
      >
        <SVGComponentPrevArrowIcon />
      </div>
    );
  }