import * as React from 'react';
import { InfoWindow, Marker } from 'react-google-maps';

import Settings from 'platform/services/settings';
import HelperComponent from 'platform/classes/helper-component';
import Maps from 'components/maps';
import { getViewEnum, formatTime } from 'platform/services/helper';
import { WeekDaysEnum } from 'platform/constants/enums';
import SearchInput from 'components/search-input';
import Modal from 'components/modal';
import { IProductDetailsResponseModel, IProductAvailablityResponseModel } from 'platform/api/product/models/response';

import './style.scss';
import PageLoader from 'components/page-loader';
import ProductController from 'platform/api/product';
import Screen from 'components/screen';
import PharmacyBranchController from 'platform/api/pharmacyBranch';
import MyMapComponent from 'modules/pages/pharmacies/components/maps';
import NewSearchResults from 'modules/pages/pharmacies/components/newSearchResult';
import { SVGComponentCloseIcon } from 'components/svg-components';

interface IProps {
  data: IProductDetailsResponseModel;
  onClose(): void;
};
interface IState {
  data?: IProductAvailablityResponseModel;
  searchValue: string;
  hoveredMarkerIndex?: number;
};

export interface AddressType {
  addressLat: number;
  addressLng: number;
  currentID: number;
}

class PharmaciesAvailablity extends HelperComponent<IProps, any> {

  public state: any = {
    searchValue: '',
    addressLat:40.179261, 
    addressLng:44.513238,
    zoom:12,
    currentID:0
  };

  public componentDidMount() { this.fetchData(); }

  private fetchData = async () => {
    const { data } = this.props;
    const pharmacyData = await ProductController.GetAvailablity(data.id);
    this.safeSetState({ data: pharmacyData?.data?.branches });
  }

  private changeShopLocation = async ( adressZoom: AddressType) => { 
    this.setState({...adressZoom,zoom:18});
  }

  public render() {
    const { data, addressLat, addressLng, zoom, currentID } = this.state;
    const { onClose } = this.props;
    if(!data) return null
    return (
      <div className='productPharmacyDetails'>
        <button className='closeMap' onClick={onClose}><SVGComponentCloseIcon/></button>
        {!!data.length && <NewSearchResults pharmacy={data} open={true} currentID={currentID} callBack={(adressZoom:AddressType) => this.changeShopLocation(adressZoom)}/>}
        <div className='map_container'>
          <MyMapComponent
            pharmacy={data}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyB_EubnEYSSE5ZbeiGnSs4-ce-YWRj0cHQ`}
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '100%' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            centerMap={{ addressLat, addressLng, zoom }}
            currentID={currentID}
          />
        </div>
      </div>

    ) 
  }
};

export default PharmaciesAvailablity;
