import * as React from 'react';

import Settings from 'platform/services/settings';
import HelperComponent from 'platform/classes/helper-component';
import { countryCode } from 'platform/constants';
import LoaderContent from 'components/loader-content';
import { ModalContentEnum } from '../../constants/enums';
import { ILoginRequestModel } from 'platform/api/auth/models/request';
import { validateForm } from './services/helper';
import AuthController from 'platform/api/auth';
import SocialButton from '../social-button';
import { SocialProvider } from 'platform/api/auth/constants/enums';
import environment from 'platform/services/environment';
import PasswordInput from "../../../../../components/password-input";

interface IProps {
  onTypeChange<ActiveData extends object>(type: ModalContentEnum, activeData?: ActiveData): void;
};

interface IState {
  form: ILoginRequestModel;
  submited: boolean;
  submitLoading: boolean;
};

class SignIn extends HelperComponent<IProps, IState> {

  public state: IState = {
    submited: false,
    submitLoading: false,
    form: {
      username: '',
      password: '',
      countryCode:''
    },
  };

  private get formValidation() {
    const { submited, form } = this.state;
    return validateForm?.call(form, submited);
  }

  private restorePassword = () => {
    const { onTypeChange } = this.props;
    onTypeChange(ModalContentEnum.SendCode);
  }

  private signUp = () => {
    const { onTypeChange } = this.props;
    onTypeChange(ModalContentEnum.SendCode);
  }

  private changeField = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { form } = this.state;
    form[e.currentTarget.name] = e.currentTarget.value;
    this.safeSetState({ form })
  }

  private submit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const form = {...this.state.form};
    this.safeSetState({ submited: true }, async () => {
      this.formValidation.valid && this.safeSetState({ submitLoading: true }, async () => {
        form.username = form.username;
        form.countryCode = countryCode;
        
        const result = await AuthController.Login(form);
        if (result.data) {
          Settings.token = result.data.accessToken;
          window.location.reload();
        } else this.safeSetState({ submitLoading: false });
      });
    });
  }

  public render() {
    const { form, submitLoading } = this.state;

    return <div className='signIn_popUp'>
        <h3 className="G-clr-main G-text-center ">{Settings.translations.log_in_title}</h3>
        {/* <span className="P-sign-in-register-text">{Settings.translations.a_member}</span> */}
        <form className="G-main-form">
          <div className="G-main-form-field firstField G-phone-input-wrapper G-mb-0">
            <p className='placeUpHolder'>{Settings.translations.enter_your_phone_number}</p>
            <div className='G-input-country-code-main'>
              <p className="G-input-country-code">+{countryCode}</p>
              <input
                autoComplete={'off'}
                name="username"
                value={form.username}
                placeholder={Settings.translations.phone_number}
                className={`G-main-input ${this.formValidation.errors.username ? 'G-invalid-field' : ''}`}
                onChange={this.changeField}
                type='number'
              />
            </div>

            <span className='P-error-message' style={{opacity: form.username && this.formValidation.errors.username ? 1 : 0}}>Phone number format is invalid</span>
          </div>
          <div className="G-main-form-field secondField">
            <p className='placeUpHolder'>{Settings.translations.password_confirmationtext}</p>
            <PasswordInput
                value={form.password}
                name="password"
                placeholder={Settings.translations.password}
                className={`G-main-input ${this.formValidation.errors.password ? 'G-invalid-field' : ''}`}
                onChange={this.changeField}
            />
          </div>
          {!environment.WHOLESALE && 
          <span className="P-sign-in-restore-password" onClick={this.restorePassword}>{Settings.translations.restore_password}</span>}
          <LoaderContent
            className="G-main-button"
            loading={submitLoading}
            onClick={this.submit}
            id={'login_btn'}
          >{Settings.translations.log_in}</LoaderContent>
        </form>

        <div className='sign_up_button'>
          <span className="P-sign-in-register-text">{Settings.translations.not_a_member}</span>
          <button id={'sign_up_btn'} className=" P-sign-in-register G-main-button" onClick={this.signUp}>{Settings.translations.sign_up}</button>
        </div>
    </div>;
  }
}

export default SignIn;
