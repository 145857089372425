import * as React from 'react';

import { INewsDetailsResponseModel } from 'platform/api/news/models/response';

import './style.scss';
import Moment from "react-moment";

interface IProps {
  data: INewsDetailsResponseModel;
}

const Info = React.memo(({ data }: IProps) => (
    <div>
    <Moment format="DD.MM.YYYY">{data.createdDate.split("T").join(" ").split(".")[0]}</Moment>
    <div className="P-news-details-info">
      <h2 className="P-name">{data.title}</h2>
    {/*<p className="P-description">{data.shortDescription}</p>*/}
    </div>
  </div>
));

export default Info;
