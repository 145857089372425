import * as React from 'react';

import HelperComponent from 'platform/classes/helper-component';
import { byPrivateRoute } from 'platform/decorators/routes';
import ROUTES from 'platform/constants/routes';
import Layout from '../../components/layout';
import Settings from 'platform/services/settings';
import OrderController from 'platform/api/order';
import { IOrderListResponseModel, IOrderDetailsResponseModel } from 'platform/api/order/models/response';
import List  from './components/list';
import Details from './pages/details';
import Pagination from 'components/pagination';
import { paginationPageLimit } from 'platform/constants';
import { IPagingResponse } from 'platform/constants/interfaces';
import EmptyState from 'components/empty-state';
import * as animationData from 'assets/animations/EmptyOrderList.json';

import './style.scss';
import ShimmerLoading from 'components/loader-content/shimmerLoading';
import EmptyStateOrders from 'components/empty-state/orders';

interface IState {
  data?: IPagingResponse<IOrderListResponseModel>;
};

@byPrivateRoute(ROUTES.PROFILE.ORDERS.MAIN)
class Orders extends HelperComponent<{}, IState> {

  public state: IState = {};

  private fetchData = async (pageNumber: number) => {

    const body:any = {
      paging:{
        count: paginationPageLimit,
        page: pageNumber
      }
    };

    const result = await OrderController.GetHistory(body);

    this.safeSetState({ data: result.data });
    return result.data;
  }

  public render() {
    const { data } = this.state;
    return (
      <Layout>
          <div className='orderDetailPage'>
            <h2 className="orders_title">{Settings.translations.order_history}</h2>
            <div className="P-profile-orders">
              {data ? (data?.data.length ? <List
                redirectUrl={(row: IOrderDetailsResponseModel) => ROUTES.PROFILE.ORDERS.DETAILS.replace(':id', row.id)}
                data={data?.data}
              /> : <EmptyStateOrders />) : <ShimmerLoading MClass={"P-profile-ordersShimmer"} colCount={6} childColCount={1} className={"productShimmer"}/>}
            </div>
            <Pagination<IOrderListResponseModel> fetchData={this.fetchData} />
          </div>
      </Layout>
    );
  }
}

export default { Orders, Details };
