import * as React from 'react';
import './style.scss';
import Settings from 'platform/services/settings';
import { OrdersEmptySTateSvg } from 'components/svg-components';


const EmptyStateOrders = React.memo(() => {

  return (
    <div className='OrdersEmptyMain'>
      <OrdersEmptySTateSvg/>
      <span className='P-desc'>{Settings.translations.empty_orders_list}</span>
    </div>
  );
})

export default EmptyStateOrders;
