import * as React from 'react';
import { Link } from 'react-router-dom';

import {
  IOrderListResponseModel,
  IOrderDetailsResponseModel
} from '../../../../../../../platform/api/order/models/response';
import Settings from '../../../../../../../platform/services/settings';
import { formatDate, formatPrice, getViewEnum } from '../../../../../../../platform/services/helper';
import { statusColorClassNames, statusColorClassNamesNew } from '../../constants';
import { OrderStatusEnum } from '../../../../../../../platform/api/order/constants/enums';
import addressIcon from "/src/assets/images/icons/address.svg"
import calendarIcon from "/src/assets/images/icons/ic_date.svg"
import './style.scss';

interface IProps {
  data: any;
  redirectUrl?(row: IOrderDetailsResponseModel, index?: number): string;
};

const List = React.memo(({ data, redirectUrl }: IProps) => {
    const statusViewEnum = getViewEnum(OrderStatusEnum);

    return (<>
      {data && data.map((item: IOrderDetailsResponseModel, index: number) =>{
        return (
          <Link key={index} to={redirectUrl ? redirectUrl(item, index) : ''} className="P-order-item-box">
            <div className="P-orders-det">
              <div className="P-order-id-status">
                <span>#{item.id}</span>
                <span id="status" className={statusColorClassNamesNew[item.status]}>{Settings.translations[statusViewEnum[item.status]]}</span>
              </div>

              <p className="P-order-date">
                <img src={calendarIcon}/>
                <span>{formatDate(item.createdDate)}</span>
              </p>

              <p className="P-order-date">
                <img src={addressIcon}/>
                <span>{item.address}</span>
              </p>

              <p className="P-order-Total">
                <span>{Settings.translations.total}</span>
                <span>{formatPrice(item.totalPrice)}</span>
              </p>
            </div>
          </Link>
        )
      })}

    </>);
  }
);

export default List;
