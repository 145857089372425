import RouteService from '../services/routes';


const queryLanguage = typeof localStorage === 'undefined' || !localStorage.getItem('language') ? typeof window === 'undefined' ? "hy" : window?.location?.pathname?.split('/')[1] : localStorage.getItem('language')
const PROFILE_ROUTES = (() => {
  const ADDRESSES_ROUTES = RouteService.buildRouteContext(`/${queryLanguage}/profile/addresses`, {
    MAIN: '',
    CREATE: '/create',
    UPDATE: '/update/:id',
  });

  const ORDERS_ROUTES = RouteService.buildRouteContext(`/${queryLanguage}/profile/orders`, {
    MAIN: ``,
    DETAILS: `/details/:id`,
    DETAILS_IDRAM: `/idram/sucesss`,
  });

  const FAVORITES_ROUTES = RouteService.buildRouteContext(`/${queryLanguage}/profile/favorites`, {
    MAIN: '',
    SAVED_BASKET_ITEMS: '/saved-basket-items/:id',
    SAVED_BASKET: '/saved-baskets',
  });

  const PRESCRIPTIONS_ROUTES = RouteService.buildRouteContext(`/${queryLanguage}/profile/prescriptions`, {
    MAIN: '',
    CREATE: '/create',
    DECIPHERED: '/deciphered/:id',
  });

  const MY_REGISTRATIONS_ROUTES = RouteService.buildRouteContext(`/${queryLanguage}/my-registrations`, {
    MAIN: '',
    MEDICAL_HISTORY: '/medical-history',
  });

  return RouteService.buildRouteContext(`/${queryLanguage}/profile`, {
    MAIN: '',
    MOBILE: '/mobile',
    ORDERS: ORDERS_ROUTES,
    ADDRESSES: ADDRESSES_ROUTES,
    SPECIAL_PRODUCTS: '/special-products',
    BONUS_CARD: '/bonus-card',
    MY_ORDERS: '/my-orders',
    MY_COMPANY: '/my-company',
    MY_REQUESTS: '/my-requests',
    MY_ADDRESSES: '/my-addresses',
    MY_WALLET: '/my-wallet',
    MY_INVITES: '/invite',
    NOTIFICATIONS: "/notifications",

    FAVORITES: FAVORITES_ROUTES,
    PRESCRIPTIONS: PRESCRIPTIONS_ROUTES,
    MY_REGISTRATIONS: MY_REGISTRATIONS_ROUTES,
  });
})();

const BLOG_ROUTES = RouteService.buildRouteContext(`/${queryLanguage}/blogs`, {
  MAIN: '',
  DETAILS: `/:id`,
});

const FAQ_ROUTES = RouteService.buildRouteContext(`/${queryLanguage}/faq`, {
  MAIN: '',
  DETAILS: `/:id/:slug`,
});

const VACANCIES_ROUTES = RouteService.buildRouteContext(`/${queryLanguage}/vacancies`, {
  MAIN: '',
  DETAILS: `/:id/:slug`,
});

const NEWS_ROUTES = RouteService.buildRouteContext(`/${queryLanguage}/news`, {
  MAIN: '',
  DETAILS: '/:id',
});

const TENDERS_ROUTES = RouteService.buildRouteContext(`/${queryLanguage}/tenders`, {
  MAIN: '',
  DETAILS: '/:id',
});

const CLINIC_ROUTES = RouteService.buildRouteContext(`/${queryLanguage}/clinic`, {
  MAIN: '',
  DOCTORS: '/doctors',
  LABORATORY: '/laboratory',
  PRICE_LIST: '/price-list',
  ABOUT_US: '/about-us',
});

const PRODUCTS_ROUTES = RouteService.buildRouteContext(`/${queryLanguage}/products`, {
  MAIN: '',
  SIMILAR: '/similar/:id',
  DETAILS: '/details/:id/:slug',
});

const WISH_LIST_ROUTES = RouteService.buildRouteContext(`/${queryLanguage}/wish-list`, {
  MAIN: '',
  INVITATION: '/invitation/:code',
});

const ROUTES = {
  HOME: `/${typeof localStorage === 'undefined'|| !localStorage.getItem('language') ? "hy" : `${localStorage.getItem('language')}${window.location.pathname.split('/')[2] === undefined? ''
  :"/"+window.location.pathname.split('/')[2] }${window.location.pathname.endsWith('/')?"":"/"}${window.location.href.includes('?fb')?window.location.href.includes("details")?'details/' + window.location.href.split('details/')[1]:"":""}${window.location.href.includes("?")?"?"+window.location.href.split("?")[1]:""}${window.location.pathname.split('/')[3] === undefined? '':window.location.pathname.split('/')[3]+"/"}${window.location.pathname.split('/')[4] === undefined? '':window.location.pathname.split('/')[4]}`}`|| 'hy',

  MN: `/${queryLanguage}`,
  CATEGORY: `/${queryLanguage}/category`,
  CART: `/${queryLanguage}/cart`,
  CHECKOUT: `/${queryLanguage}/checkout`,
  ABOUT_US: `/${queryLanguage}/about-us`,
  HOW_TO_ORDER_ONLINE: `/${queryLanguage}/how-to-order-online`,
  FINANCIAL_STATEMENTS: `/${queryLanguage}/financial-statements`,
  CONTACT: `/${queryLanguage}/contact`,
  BUSINESS: `/${queryLanguage}/business`,
  CONTRACT: `/${queryLanguage}/contract`,
  SERVICES: `/${queryLanguage}/services`,
  CLINIC: CLINIC_ROUTES,
  PHARMACIES: `/${queryLanguage}/pharmacies`,
  CARD_INFO: `/${queryLanguage}/card-info`,
  EXCHANGE_RETURN: `/${queryLanguage}/exchange-return`,
  WHOLESALE_NEWS: `/${queryLanguage}/wholesale-news`,
  WHOLESALE_PROMOTIONS: `/${queryLanguage}/wholesale-promotions`,
  CONFIDENTIALITY_CONDITIONS: `/${queryLanguage}/conditions`,
  PRIVACY_POLICY: `/${queryLanguage}/privacy-policy`,
  TERMS_OF_USE: `/${queryLanguage}/terms-of-use`,
  THANK_FOR_PAYMENT: `/${queryLanguage}/thank-for-payment`,
  PRODUCT_SALES: `/${queryLanguage}/product-sales/:kind`,
  // SUCSESS: `ru/checkout`,
  // FAIL: `/en/checkout/idram/fail`,
  FAQ: FAQ_ROUTES,
  VACANCIES: VACANCIES_ROUTES,
  BLOG: BLOG_ROUTES,
  NEWS: NEWS_ROUTES,
  TENDERS: TENDERS_ROUTES,
  PROFILE: PROFILE_ROUTES,
  PRODUCTS: PRODUCTS_ROUTES,
  WISH_LIST: WISH_LIST_ROUTES,
}

export default ROUTES;