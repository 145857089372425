import './style.scss';
import * as React from 'react';
import ROUTES from 'platform/constants/routes';
import {byRoute} from 'platform/decorators/routes';
import HelperComponent from 'platform/classes/helper-component';
import Storage from 'platform/services/storage';
import { ProductSortEnum } from 'platform/api/product/constants/enums';
import ShimmerLoading from 'components/loader-content/shimmerLoading';


@byRoute([ROUTES.CATEGORY])
class CategoryMobile extends HelperComponent<{}, any> {

  private clickItem = (e: React.SyntheticEvent, category: any) => {
    e.preventDefault();

    if (!category.id) window.location.href=(`${ROUTES.PRODUCTS.MAIN}?sortBy=${ProductSortEnum.PriceHighToLow}&hasDiscount=true`);
    else window.location.href=(`${ROUTES.PRODUCTS.MAIN}?categoryIds=${category.id}&sortBy=${ProductSortEnum.PriceHighToLow}`);
  }


  private getRandomColor() {
    const r = Math.floor(Math.random() * 256); // Random red value between 0 and 255
    const g = Math.floor(Math.random() * 256); // Random green value between 0 and 255
    const b = Math.floor(Math.random() * 256); // Random blue value between 0 and 255
    return `rgba(${r}, ${g}, ${b}, ${0.2})`;
  }



  public render() {

    if(!Storage?.categories?.length) return <ShimmerLoading MClass={'allCategoryShimmer'} colCount={10} childColCount={1} className={"productShimmer"}/>
    return (
      <div className='categoryPage'>
          {Storage?.categories?.map((category) => {
            const color = this.getRandomColor()
            return (
              <button key={category.id} style={{background:color}} className='linkToCategory' onClick={e => this.clickItem(e, category)}>
                {category.name}
              </button>
            )
          })}
      </div>
    );
  }
}

export default CategoryMobile;