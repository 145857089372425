// import * as React from 'react';
import React from 'react';
// @ts-ignore
import {createBrowserHistory} from 'history';
import {Router, Route, Switch, Redirect, Link} from 'react-router-dom';
import Header from './components/header';
import RouteService from './platform/services/routes';
import ROUTES from './platform/constants/routes';
import Storage from './platform/services/storage';
import Settings from './platform/services/settings';
import Footer from './components/footer';
import FooterApp from './components/footer-app';
import ConfirmModal, {IProps as IConfirmModalProps} from './components/confirm-modal';
import Socket from './platform/services/socket';
import HelperComponent from './platform/classes/helper-component';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import './modules';
import 'moment/locale/hy-am';
import 'moment/locale/ru';
import 'moment/locale/en-gb';
import './assets/styles/index.scss';
import PaymentController from './platform/api/payment';
import Maps from "./components/maps";
import SuccessModal from "./components/success-modal";
import Environment from "./platform/services/environment";
import BasketController from 'platform/api/basket';
import moment from 'moment';


interface IState {
    confirmOpen: boolean;
    confirmProps: IConfirmModalProps;
    initialLoading: boolean;
    generalAPILoaded: boolean;
    successPayment: boolean;
    bannerModal:boolean;
    spinModal:boolean;
    basketCount:number,

};
class App extends HelperComponent<{}, IState> {
    public state: IState = {
        basketCount:0,
        generalAPILoaded: false,
        initialLoading: false,
        confirmOpen: false,
        successPayment: false,
        bannerModal:false,
        spinModal: false,
        confirmProps: {}
    };
    private loadingTimeout: number;

    public componentWillUnmount() {
        super.componentWillUnmount();
        this.loadingTimeout && clearTimeout(this.loadingTimeout);
    }

    public async componentDidMount() {
        const root = document.documentElement;

        // this is for lang font 
        let lang = localStorage.getItem("language")
        const pathName = window?.location?.pathname?.split('/')[1]
        if(!lang) {
            window?.localStorage.setItem('language', pathName)
            lang = localStorage.getItem("language")
        } 
        
        if(!pathName) {
            window.location.href = Environment.SITE_URL + Settings.language
        }
        
        const fontFamily = (lang === "hy") ? "Mardoto-Light" : "Roboto-Regular"
        root.style.setProperty('--custom-font-family', fontFamily);

        const realTime = new Date();
        const storageTime = window.localStorage.getItem('BannerOpenTime') || 0;
        let spinCount = Number(localStorage.getItem('spinCount')) || 0;
        localStorage.setItem('spinCount',JSON.stringify(++spinCount));
        if (storageTime === 0 || realTime.setDate(realTime.getDate())>parseInt(storageTime,10)) {
            const time = realTime.setDate(realTime.getDate() + 1);
            window.localStorage.setItem('BannerOpenTime', JSON.stringify(time));
            window.localStorage.setItem('spinCount', JSON.stringify(1))
            this.loadingTimeout = window.setTimeout(() => this.safeSetState({ bannerModal: true }), 10000);
        } else {
            spinCount <= 3 ? this.loadingTimeout = window.setTimeout(() => this.safeSetState({ bannerModal: true }), 10000) : this.state.bannerModal = false;
        }
        await this.checkForPaymentSuccess();

        const alertify = await import('alertifyjs');
        moment.locale(Settings.shortCode);
        alertify.set('notifier', 'position',"bottom-left");
        alertify.set('notifier', 'delay', 5);

        window.abortableRequests = [];
        window.routerHistory = createBrowserHistory();
        window.routerHistory.listen(() => {
            if (location.pathname !== '/products') {
                setTimeout(() => {window.scrollTo(0, 0)});
            }
        });
        window.addEventListener(DispatcherChannels.ToggleConfirm, this.toggleConfirm);
        window.addEventListener(DispatcherChannels.GetBasketCount, this.getBasketCount);
        window.addEventListener(DispatcherChannels.CartItemsUpdate, this.getBasketCount);
        window.addEventListener(DispatcherChannels.logOut, Storage.fetchDefault);

        this.safeSetState({generalAPILoaded: true});

        try { await Socket.connect();
        } catch { /* */ }
        this.getBasketCount()
        const successCategory = await Storage.fetchDefault();
        if (successCategory) this.safeSetState({ initialLoading: true })
        else { window.location.reload() };

        const query = new URLSearchParams(window.location.search);
        const referralCode = query.get('referral');
        if (referralCode) {
            Settings.referralCode = referralCode;
        }
    }

    private getBasketCount = async () => {
        if (!Settings.guest) {
            BasketController.GetCount().then(result => {
              this.safeSetState({ basketCount: result.data });
            }).catch(error => {
              console.error('Error fetching basket count:', error);
            });
        }
    };

    private checkForPaymentSuccess = async () => {
        const query = new URLSearchParams(window.location.search);
        const orderId = query.get('orderId');
        const isCard = query.get('isCard');

        if (orderId) {
            if (isCard) {
                await PaymentController.saveCard(orderId);
                query.delete('orderId');
                query.delete('key');
                query.delete('isCard');
                window.history.replaceState({path: window.location.pathname}, '', `?${query}`);
            } else {
                const result = await PaymentController.confirm(orderId);
                if (result.success) {
                    window.location.href = Environment.SITE_URL.slice(0, -1)+ROUTES.THANK_FOR_PAYMENT
                } else {
                    window.location.href = Environment.SITE_URL+Settings.language+'/profile/orders?FailPayment';
                }
            }
        }
    };

    private toggleConfirm = (e: CustomEvent) => {
        const {confirmOpen} = this.state;
        this.safeSetState({confirmOpen: !confirmOpen, confirmProps: e.detail || {}});
    };
    public map = <Maps/>

    public render() {
        const {generalAPILoaded, initialLoading, confirmOpen, confirmProps, basketCount} = this.state;
        if (typeof window === 'undefined' && initialLoading) { return null }
        
        return generalAPILoaded ? (     
            <Router history={window?.routerHistory}>
                {window.location.href.includes("FailPayment") ? 
                <SuccessModal bool2={true} bool1={false} onClose={() => window.location.href = Environment.SITE_URL + Settings.language}>
                    {Settings.guest ? <>
                        <h3>{Settings.translations.order_fail}</h3>
                        <button id={'history'} 
                            className="G-main-button G-normal-link G-mt-30 P-register-button"
                            onClick={() => {
                                window.location.href = Environment.SITE_URL+Settings.language+"/profile/orders"
                            }}>
                            {Settings.translations.order_history}
                        </button>
                    </> : 
                    <>
                        <h3>{Settings.translations.order_fail}</h3>
                        <Link 
                            className="G-main-button G-normal-link G-mt-30"
                            to={ROUTES.PROFILE.ORDERS.MAIN}
                        >
                            {Settings.translations.order_history}
                        </Link>
                    </>}
                </SuccessModal> : null}

                <Header initialLoading={initialLoading} basketCount={basketCount}/>

                <section className="G-page-content">
                    <Switch>
                        {RouteService.subscribeUnauthorized(routes => routes.map(item => <Route
                            exact={true}
                            key={item.path}
                            path={item.path}
                            render={(props) => (
                                <item.component initialLoading={initialLoading} {...props} />
                            )}
                        />))}

                        {!!Settings.token && !Settings.guest && RouteService.subscribeAuthorized(routes => routes.map(item => <Route
                            exact={true}
                            key={item.path}
                            path={item.path}
                            component={item.component}
                        />))}
                        {/* {window.location.href === Environment.SITE_URL && <Redirect to={ROUTES.MN}/>} */}
                        <Redirect to={ROUTES.MN}/>
                    </Switch>
                </section>
                {confirmOpen && <ConfirmModal 
                        title={Settings.translations.removeAddressP}
                        text={Settings.translations.removeAddressPUnderLine}
                        {...confirmProps} 
                    />
                }
                <FooterApp basketCount={basketCount}/>
                <Footer/>
            </Router>
        ) : null;
    }
}
export default App;