import { SVGComponentSearchSlack, SVGFilterIcon } from 'components/svg-components';
import React, { useState, useRef, useEffect } from 'react';


interface CustomSelectType {
  cities: { id: number, name: string }[]
  callBack: (id:number) => void;
  selectedCityId: number;
}



function CustomSelect({cities,callBack,selectedCityId}:CustomSelectType) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement | null>(null);

  const selectedCity = cities?.find((val) => val.id === selectedCityId)

  const handleSelectToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionSelect = (id: number) => {
    callBack(id);
    setIsDropdownOpen(false);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    const handleOutsideClickWithRef = (e: MouseEvent) => handleOutsideClick(e);
    document.addEventListener('mousedown', handleOutsideClickWithRef);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClickWithRef);
    };
  }, []);


  return (
    <div className="custom-select" ref={selectRef}>
      <div className="selected-value" onClick={handleSelectToggle}>
        <span className='title'>{selectedCity?.name}</span>
        <span className='mobileDropDownButtonMobile'> <SVGFilterIcon/> </span>
        <span className='mobileDropDownButtonDesktop'> <SVGComponentSearchSlack/> </span>
      </div>
      {isDropdownOpen && (
        <div className='optionMain' onClick={handleSelectToggle}> 
          <ul className="options" onClick={(e) => e.stopPropagation()}>
            {cities?.map((city:any) => (
              <li key={city.id} onClick={() => handleOptionSelect(city.id)}>
                {city.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default CustomSelect;
