import * as React from 'react';
import ROUTES from 'platform/constants/routes';
import { byRoute } from 'platform/decorators/routes';
import HelperPureComponent from 'platform/classes/helper-pure-component';
import { IStaticResponseModel } from 'platform/api/static/models/response';
import StaticController from 'platform/api/static';
import './style.scss';

interface IState {
  data?: IStaticResponseModel;
  data2?: any;
}

@byRoute([ROUTES.CARD_INFO])
class CardInfo extends HelperPureComponent<{}, IState> {

  public state: IState = {};
  public async componentDidMount() {
    const result2 = await StaticController.GetContentType(1)
    this.safeSetState({ data2: result2.data});
  }
  public render() {
    const { data2 } = this.state;

    return (
      <section className="G-page P-card-info-page">
        <h2 className="G-page-title">{data2?.title}</h2>
        {data2 && <div className="P-content" dangerouslySetInnerHTML={{ __html: data2.description }} />}
      </section>
    );
  }
}

export default CardInfo;
