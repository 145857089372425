import * as React from 'react';
import './style.scss';
import Settings from 'platform/services/settings';
import { FavoriteEmptySTateSvg } from 'components/svg-components';


const EmptyStateFavorite = React.memo(() => {

  return (
    <div className='FavoriteEmptyMain'>
      <FavoriteEmptySTateSvg/>
      <span className='P-desc'>{Settings.translations.emptyFavoriteText}</span>
    </div>
  );
})

export default EmptyStateFavorite;
