import { IUserAddressModifyRequestModel } from 'platform/api/userAddress/models/request';
export function validateForm(this: IUserAddressModifyRequestModel, submited?: boolean) {
  let valid = true;

  
  const errors = {
    name: false,
    address: false,
  };


  if (submited && !this.name.trim()) {
    errors.name = true;
    valid = false;
  }

  if (submited && (!this.addressText)) {
    errors.address = true;
    valid = false;
  }

  
  return {
    errors,
    valid,
  };
}

export function validateFormNew(this: any, submited?: boolean) {
  let valid = true;
  const errors = {
    building: false,
    addressText: false,
  };

  if (submited && !this.building.trim()) {
    errors.building = true;
    valid = false;
  }

  if (submited && (!this.addressText)) {
    errors.addressText = true;
    valid = false;
  }
      
  return {
    errors,
    valid,
  };
}