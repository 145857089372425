
import * as React from 'react';

import LeftSide from '../left-side';

import './style.scss';
import Settings from 'platform/services/settings';
import { SVGComponentBackToIcon } from 'components/svg-components';
import PersonImage from '../../pages/personal-info/components/personIMage';

interface IProps {
  children: React.ReactNode;
};

const Layout = React.memo(({ children }: IProps) => {
  const urlParts = window.location.href.split('/');
  const profileIndex = urlParts.indexOf('profile');
  const nextWord = urlParts[profileIndex + 1];
  const bonusCardRoute = window.location.pathname.includes("bonus-card")

  return (
    <section className="G-page P-profile-page">
      <p className='Profile_page_title'>{Settings.translations.Profile_page_title}</p>
      {/* <div className='backToMenu' onClick={() => window.routerHistory.goBack()}>
        <SVGComponentBackToIcon/>
        <p className='menuTitle'>{Settings.translations.back_menu}</p>
      </div> */}
      {(window.location.href.includes("mobile") || !nextWord ) && 
        <div className='mainMobaileImage'>
          <PersonImage/>
        </div>
      }

      <div className='P-profile-page_child'>
        <LeftSide />
        <div className="P-content">
          {children}
        </div>
      </div>
      
      {bonusCardRoute ? 
      <div className='about_bonus_card'>
        <h1 className='aboutTitle'>{Settings.translations.abouis_natali_pharm}</h1>
        <div className='description'>
          {Settings.translations.abouisDescription_natali_pharm}
        </div>
      </div> : null}
      
    </section>
  )
});

export default Layout;