import * as React from 'react';

import Settings from 'platform/services/settings';
import { Shared } from 'modules';
import Slider from "react-slick";
import { IProductListResponseModel, IProductDetailsResponseModel } from 'platform/api/product/models/response';
import ProductController from 'platform/api/product';

import './style.scss';
import { SVGComponentNextArrowIcon, SVGComponentPrevArrowIcon } from 'components/svg-components';
import ShimmerLoading from 'components/loader-content/shimmerLoading';

interface IProps {
  data: any;
};

const Similar = React.memo(({ data }: any) => {
  const [list, setList] = React.useState<IProductListResponseModel[]>([]);
  const [isLoading, setIsLoading] = React.useState<any>(true);


  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: list.length < 5 ? list.length : 5,
    slidesToScroll: 1,
    nextArrow: <div><SVGComponentNextArrowIcon /></div>,
    prevArrow: <div><SVGComponentPrevArrowIcon /></div>,
    variableWidth:true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  }

  React.useEffect(() => {
    if(data) {
      ProductController.GetRelated({
        id: data.id,
        paging: {
          count: 10,
          page: 1
        }
      })
      .then((result:any) => {
        setIsLoading(false)
        setList(result?.data?.data || []);
      })
    }
  }, [data]);

  function handleChange(id:number,isFavorite:boolean) {
    const newList = list.map((val:any) => {
      if(val.id === id) {
        return {
          ...val, 
          isAddedToBasket:true,
          isFavorite
        }
      } else {
        return val
      }
    })
    setList(newList)
  }
  return isLoading ? <ShimmerLoading MClass={'P-DetShimmer'} colCount={5} childColCount={5} className={"productShimmer"}/> : list.length ? (
    <div className="P-product-details-similar">
      <h2 className="P-title">{Settings.translations.similar_products}</h2>
      <div className="P-list">
        <Slider
          {...settings}
          arrows={true}
        >
          {list.map(item => <div key={item.id}>
            <Shared.Products.SimilarListItem data={item} callBack={(id:number,isFavorite:boolean) => handleChange(id,isFavorite)}/>
          </div>)}
        </Slider>
      </div>
    </div>
  ) : null;
});

export default Similar;