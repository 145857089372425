import * as React  from 'react';
import LaddaButton from 'react-ladda';

import HelperPureComponent from 'platform/classes/helper-pure-component';
import Settings from 'platform/services/settings';
import ROUTES from 'platform/constants/routes';
import { Link } from 'react-router-dom';

interface IProps {
  type?: string;
  className: string;
  color?: string;
  disabled: boolean;
  loading?: boolean;
  children?: React.ReactNode;
  imagecontent: any;
  style?: object;
  isaddedtobasket?:boolean
  onClick?(e: React.SyntheticEvent<HTMLElement>): void;
};

interface IState {
  loading: boolean;
};

class LoaderContent extends HelperPureComponent<IProps, IState> {

  public static defaultProps = {
    type: null,
    className: '',
    disabled: false,
    children: null,
    loading: false,
    imagecontent: false,
    id:''
  };

  public state: IState = {
    loading: false,
  }


  public componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.loading !== this.state.loading && nextProps.loading) {
      setTimeout(() => this.safeSetState({ loading: true }), 500);
    } else if(nextProps.loading !== this.state.loading && this.state.loading) {
      this.safeSetState({ loading: false });
    }
  }

  public componentWillUnmount() {
    super.componentWillUnmount();
  }

  public handelChangeRoute(e:any) {
    e.preventDefault()
    window.routerHistory.push(ROUTES.CART);
  }

  public render() {
    const { children, className, imagecontent, color, isaddedtobasket } = this.props;
    const FilteredProps = {...this.props};
    !FilteredProps.type && delete FilteredProps.type;
    FilteredProps.className = `${className} ${!!imagecontent ? 'ladda-image' : ''}`
    delete FilteredProps.isaddedtobasket;

    if(isaddedtobasket) {
      return (
      <div id="newButtonDisabled" onClick={this.handelChangeRoute} className="G-main-button newButtonDisabled">
        {Settings.translations.edit_to_cart_short}
      </div>
      )
    } else {
      return (
        <LaddaButton
          data-spinner-size={30}
          data-spinner-lines={40}
          data-spinner-color={'green' }
          {...FilteredProps}
          imagecontent={`${imagecontent}`}
        >{children}</LaddaButton>
      )
    }
  };
};


export default LoaderContent;
