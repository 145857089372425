import React, { lazy } from 'react';
import HelperComponent from 'platform/classes/helper-component';
import OriginalListItem from './components/list-item';
import { IProductListResponseModel } from 'platform/api/product/models/response';
import ProductController from 'platform/api/product';
import Slider from "react-slick";
import environment from 'platform/services/environment';
import ShimmerLoading from 'components/loader-content/shimmerLoading';
import { SampleNextArrow } from '../arrowComponetn/nextArrow';
import { SamplePrevArrow } from '../arrowComponetn/prevArrow';
import Storage from 'platform/services/storage';
import Settings from 'platform/services/settings';
import './style.scss';

interface IState {
  data: IProductListResponseModel[];
  isAddressAdded:boolean;
};

class AllProductsList extends HelperComponent<{}, IState> {

  public state: IState = {
    data: [],
    isAddressAdded:false
  };

  public componentDidMount() {
    this.fetchData();
    this.fetchDefaultAddress();
  }

  private fetchDefaultAddress = async (key?:any) => {
    this.setState({isAddressAdded:!!Storage?.currentAddress?.addressText})
  };

  private fetchData = async () => {
    const result:any = await ProductController.GetCategoryList(environment.childOffers,10);
    this.safeSetState({ data: result?.data || []});
    
  }

  public settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToScroll: 1,
    nextArrow:<SampleNextArrow/>,
    prevArrow:<SamplePrevArrow/>,
    variableWidth: true,
    variableHeight:true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 766,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
    ]
  }

  public render() {
    const { data, isAddressAdded } = this.state;
    
    if(!data.length){
      return <>
        <ShimmerLoading MClass={'allProductShimmer'} colCount={5} childColCount={5} className={"productShimmer"}/>
        <ShimmerLoading MClass={'allProductShimmer'} colCount={5} childColCount={5} className={"productShimmer"}/>
      </>
    }
    return (
      data.map(({id,name,products}:any) => {
        return (
          <section key={id} className="G-page P-home-discounted-products originalSection">
            <h2 className="G-page-title">{name}</h2>
            <div className="P-list-wrapper">
              <Slider
                {...this.settings}
                slidesToShow = {(products.length < 5) ? products.length : 5}
                arrows={true}
               >
                {products.map((item:any) => <div key={item.id}>
                  <OriginalListItem isGuwest={Settings.guest} isAddressAdded={isAddressAdded} data={item} fetchData={this.fetchData}/>
                </div>)}
              </Slider>
            </div>
          </section>
        )
      })
    )
  }
};

export default AllProductsList;