import * as React from 'react';
import {withRouter, RouteComponentProps, Link} from 'react-router-dom';
import generic from 'platform/decorators/generic';
import ROUTES from 'platform/constants/routes';
import { byRoute } from 'platform/decorators/routes';
import HelperComponent from 'platform/classes/helper-component';
import BlogController from 'platform/api/blog';
import moment from 'moment';
import './style.scss';
import Settings from "../../../../../platform/services/settings";
import Slider from 'react-slick';
import { SVGComponentNextArrowIconList, SVGComponentPrevArrowIconList } from 'components/svg-components';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import ShimmerLoading from 'components/loader-content/shimmerLoading';
import { ResizerImgSizeDinamic } from 'platform/hooks/useImageSize';
interface IRouteParams {
    id: string,
    slug: string
};

interface IState {
  photoIndex:number;
  isOpen:boolean;
  data?: any;
};

@generic<RouteComponentProps<IRouteParams>>(withRouter)
@byRoute(ROUTES.BLOG.DETAILS)
class Details extends HelperComponent<RouteComponentProps<IRouteParams>, IState> {
    public state: IState = {
      photoIndex: 0,
      isOpen: false,
      data: {
        lists: [],
        currantState:{}
      }
  };

  public async componentDidMount() { 
    const { location } = this.props;
    const { lists }:any = location.state || {};

    const pathSegments = location.pathname.split('/');
    const id = pathSegments[3];

    const response:any = await BlogController.GetDetails(Number(id))
    if(response.success) {
      this.setState({data:{lists,currantState:response.data}})
    }
  }

  private settings = {

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          swipe: true, 
          slidesToShow: 3,
        }
      },
    ]
  };
  private goBack = () => window.routerHistory.goBack();

  public render() {
    const { lists,currantState } = this.state.data;
    const { isOpen,photoIndex } = this.state;

    return (
      <section className="blog-detail">

        <div className='mainDetailBlog'>
          <button className='backToButton' onClick={this.goBack}>
            {window.routerHistory.length > 2 && <i className="icon-Group-5529"/>}
            {Settings.translations.backToAllArticls}
          </button>
          <div className='mainItem'>
            {
              currantState?.images  ? <img alt='mainImg' className='mainImg' src={ResizerImgSizeDinamic(currantState?.images[0]?.path,[600,600])}/>
              : <ShimmerLoading childColCount={0} colCount={1} MClass={"BlogPageShimmerCard"}/>
            }
            <div className='description_field'>
                <p className='date'>{moment(currantState?.createdDate).format('MMMM Do YYYY')}</p>
                <p className='mainTitle'>
                  {currantState?.title}
                </p>
            </div>
          </div>
        </div>
            
        <div className='allItemsContainer'>
          <div className='SlideItems'>
          {!!currantState?.images?.length && 
            <Slider
              {...this.settings}
              dots={false}
              autoplay={true}
              autoplaySpeed={5000}
              slidesToShow={currantState?.images?.length < 4 ? currantState?.images?.length : 4}
              swipe={false}
              slidesToScroll={1}
              nextArrow={<div><SVGComponentNextArrowIconList /></div>}
              prevArrow={<div><SVGComponentPrevArrowIconList /></div>}
              variableWidth={true}
              className="P-home-carousel-slider"
            >
                {currantState?.images?.map(({id,path},index:any) => {
                    return (
                      <div key={id} className='items' onClick={() => this.setState({ isOpen: true, photoIndex:index })}>
                        <img alt='itemsImg' className='itemsImg' src={ResizerImgSizeDinamic(path,[600,600])}/>
                      </div>
                    )
                })}
            </Slider>}
            
            {isOpen && (
              <Lightbox
                mainSrc={currantState?.images[photoIndex]?.path}
                nextSrc={currantState?.images[(photoIndex + 1) % currantState?.images.length]?.path}
                prevSrc={currantState?.images[(photoIndex - 1 + currantState?.images.length) % currantState?.images.length]?.path}
                onCloseRequest={() => this.setState({ isOpen: false })}
                onMovePrevRequest={() => {
                  this.setState({
                    photoIndex: (photoIndex + currantState?.images.length - 1) % currantState?.images.length,
                  })
                }}
                onMoveNextRequest={() => {
                  this.setState({
                    photoIndex: (photoIndex + 1) % currantState?.images.length,
                  })
                }}
              />
            )}

            <p className='description'>
              {currantState?.shortDescription}
            </p>
          </div>

          {!!lists?.length && 
          <div className='listItemMain'>
              <p className='title'>{Settings.translations.alsoReadTitle}</p>
              <div className='items'>
                {lists?.slice(0,10)?.map(({id,title,createdDate,imagePath}) => (
                  <Link key={id} to={ROUTES.BLOG.DETAILS.replace(':id', id)} className='itemLists'>
                    <img className='itemImg' src={imagePath}/>
                    <div className='description_field'>
                        <p className='date'>{moment(createdDate).format('MMMM Do YYYY')}</p>
                        <p className='mainTitle'>
                            {title}
                        </p>
                    </div>
                  </Link>
                ))}
              </div>
          </div>}
        </div>
        {/* <PageLoader/> */}
      </section>
    )
  }
};

export default Details;


    // data ? (
    //     <>
    //       <ReactSEOMetaTags
    //           website={{
    //               title:  data.title,
    //               description: data.shortDescription,
    //               language: 'en-US',
    //               image: data.images.length ? data.images[0].path : '',
    //           }}/>
    //         <ul className="Breadcrumbs">
    //             <li className='fromRoutes'> <Link to={ROUTES.MN}>{Settings.translations.home}</Link></li>
    //             <li className='fromRoutes'><Link to={ROUTES.BLOG.MAIN}>{Settings.translations.blog}</Link> </li>
    //             <li className='activeRoute'> { data.title}</li>
    //         </ul>
    //       <section className="G-page P-blog-details-page">
    //         {window.routerHistory.length > 2 && <i className="G-back-icon icon-Group-5529" onClick={this.goBack} />}
    //         <div className="P-content">
    //           {!!data.images.length && <Images data={data} />}
    //           <Info data={data}/>
    //           <p className="P-description" dangerouslySetInnerHTML={{ __html: data.description}} />
    //         </div>
    //       </section>
    //     </>

    // ) : <PageLoader />;