
import React, { useReducer, useState } from "react"
import "./style.scss"
import Settings from "platform/services/settings"
import AutoComplate from "components/inputAutocomplate"
import { isCreate, validateFormCreate } from "./utils"
import LoaderContent from "components/loader-content"
import UserAddressController from "platform/api/userAddress"
import MainButton from "components/Button/mainButton/mainButton"
import { addresOpenEnum } from "components/header"
import DispatcherChannels from "platform/constants/dispatcher-channels"
import { SVGComponentBackToIcon } from "components/svg-components"

enum formEnum {
    name = 1,
    addressText,
    building,
    entrance,
    apartment,
    floor
}

function reducer(state:any,action:any) {
    return {
        ...state,
        [formEnum[action.type]]:action.value
    }
}

export default function AddressCreateMainPopUp({onClose,HandleOpenAddress,addressData}) {
    const [submitLoading,setSubmitLoading] = useState(false)
    const [loadButton,setLoadButton] = useState(false)

    const [form,dispatch] = useReducer(reducer,{
        name:"",
        addressText:"",
        building:"",
        entrance:"",
        apartment:"",
        floor:""
    })

    async function submit(e: React.SyntheticEvent) {
        e.preventDefault();
        setSubmitLoading(true)
        setLoadButton(true)
        
        if(isCreate(form).valid) {
            const result = await UserAddressController.Create(form);
            const resultDefault = await UserAddressController.MakeDefault(result?.data);
            if(resultDefault.success) {
                window.dispatchEvent(new Event(DispatcherChannels.checkAddress))
                window.dispatchEvent(new CustomEvent(DispatcherChannels.CartUpdate));
                window.dispatchEvent(new CustomEvent(DispatcherChannels.productDetailUpdate));
                onClose()
            }
        } else {
            setLoadButton(false)
        }
    }

    return (
        <div className="CreateAddressPopUp">
            <p className='itemTitle'>{Settings.translations.add_address}</p>
            <div className="G-main-form P-form-block">
                <div className="G-flex G-flex-justify-between P-address-form">
                    <div className="G-main-form-field G-mr-20">
                        <p className="G-input-top-label">{Settings.translations.nameAddress}</p>
                        <input
                            placeholder={`${Settings.translations.Write_input} ${Settings.translations.nameAddress.toLowerCase()}`}
                            name="name"
                            value={form.name}
                            className={`G-main-input`}
                            onChange={(e) => dispatch({type:formEnum.name,value:e.target.value})}
                        />
                    </div>
                    <div className="G-main-form-field">
                        <AutoComplate
                            onPlaceSelect={(value:any) => dispatch({type:formEnum.addressText,value})}
                            addressError={validateFormCreate(form,submitLoading).errors.addressText}
                            defaultValue={form.address}
                            fieldTitle={Settings.translations.address + ` *`}
                        />
                    </div>
                </div>

                <div className="addrerss_newMain">
                    <div className="G-main-form-field ">
                        <p className="G-input-top-label">{Settings.translations.building + ` *`}</p>
                        <input
                            placeholder={`${Settings.translations.Write_input} ${Settings.translations.building.toLowerCase()}`}
                            name="building"
                            value={form.building || ''}
                            className={`G-main-input ${validateFormCreate(form,submitLoading).errors.building ? 'G-invalid-field' : ''}`}
                            onChange={(e:any) => dispatch({type:formEnum.building,value:e.target.value})}
                        />
                    </div>
                    <div className="G-main-form-field">
                        <p className="G-input-top-label">{Settings.translations.entrance}</p>
                        <input
                            placeholder={`${Settings.translations.Write_input} ${Settings.translations.entrance.toLowerCase()}`}
                            name="entrance"
                            value={form.entrance || ''}
                            className="G-main-input"
                            onChange={(e:any) => dispatch({type:formEnum.entrance,value:e.target.value})}
                        />
                    </div>
                    <div className="G-main-form-field">
                        <p className="G-input-top-label">{Settings.translations.floor}</p>
                        <input
                            placeholder={`${Settings.translations.Write_input} ${Settings.translations.floor.toLowerCase()}`}
                            name="floor"
                            value={form.floor || ''}
                            className="G-main-input"
                            onChange={(e:any) => dispatch({type:formEnum.floor,value:e.target.value})}
                        />
                    </div>
                    <div className="G-main-form-field">
                        <p className="G-input-top-label">{Settings.translations.apartment}</p>
                        <input
                            placeholder={`${Settings.translations.Write_input} ${Settings.translations.apartment.toLowerCase()}`}
                            name="apartment"
                            value={form.apartment || ''}
                            className="G-main-input"
                            onChange={(e:any) => dispatch({type:formEnum.apartment,value:e.target.value})}
                        />
                    </div>
                </div>
                
                <div className="buttonFieldCreateMain">
                    {!!addressData?.length && <MainButton text={Settings.translations.Back} onClick={() => HandleOpenAddress(addresOpenEnum.change)} />}
                    <div className="buttonField">
                        <MainButton text={Settings.translations.cancel} onClick={onClose} />
                        <MainButton loading={loadButton} text={Settings.translations.add_address_button} onClick={submit} />
                    </div>
                </div>
            </div>
        </div>
    )
}