import * as React from 'react';
import { NavLink } from 'react-router-dom';
import HelperPureComponent from 'platform/classes/helper-pure-component';
import Storage from 'platform/services/storage';
import ROUTES from 'platform/constants/routes';
import { leftSideOptions } from './constants/routes';
import Settings from 'platform/services/settings';
import PersonImage from 'assets/images/person.png';
import './style.scss';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import Broadcast from 'platform/services/broadcast';
import { SVGSignOutProfileIcon } from 'components/svg-components';


interface IState {
  photoPath?: string;
}

class LeftSide extends HelperPureComponent<{}, {}> {
  private options = leftSideOptions();

  public state: IState = {
    photoPath: (Storage.profile && Storage.profile.photoPath) || PersonImage
  };

  public componentDidMount() {
    Broadcast.subscribe(DispatcherChannels.StorageUpdate, this.storageUpdate);
  }

  private storageUpdate = () => {
    this.forceUpdate();
  }


  private logout = () => {
    window.dispatchEvent(new CustomEvent(DispatcherChannels.ToggleConfirm, { detail: { 
      text: Settings.translations.log_out_question,
      title:Settings.translations.log_out
    }}));
    window.dispatchEvent(new CustomEvent(DispatcherChannels.logOut));

    window.addEventListener(DispatcherChannels.UserConfirmed, Settings.logout);
    window.addEventListener(DispatcherChannels.UserCanceled, this.logoutCanceled);
  }

  private logoutCanceled = () => {
    window.dispatchEvent(new CustomEvent(DispatcherChannels.ToggleConfirm));
    window.removeEventListener(DispatcherChannels.UserConfirmed, Settings.logout);
    window.removeEventListener(DispatcherChannels.UserCanceled, this.logoutCanceled);
  }

 

  public render() {
    const currentPath = window.location.pathname;
    return (
      <aside className="P-profile-left-side">
        {this.options.map((item) => (
          <NavLink
            to={item.path}
            key={item.path}
            className={'P-link'}
            activeClassName={(currentPath !== ROUTES.PROFILE.MAIN) && (item.path === ROUTES.PROFILE.MAIN) ? "" : 'P-active'}
          >
            {item.imgUrl ? <item.imgUrl /> : null}
            {item.name}
          </NavLink>
        ))}

        <div onClick={this.logout} className="P-link">
          <SVGSignOutProfileIcon/>
          {Settings.translations.log_out}
        </div>
      </aside>
    );
  }
}

export default LeftSide;