import React from "react";
import "./styles.scss";

export default function Inputs_TextField({
  name,
  checkBox,
  children
}: {
  name: any;
  checkBox?:any;
  children:any;
}) {
  return (
    <div className="input_containerCreateMember">
      <p className="parent_title">
        <span className="input_name">{name}</span>
        {/* <span id="asterick">&#42;</span> */}
        {checkBox}
      </p>

      {children}
    </div>
  );
}
