import * as React from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { RiDeleteBin5Line } from 'react-icons/ri';
import addressIcon from "/src/assets/images/icons/address.svg"

import { IUserAddressListResponseModel } from '../../../../../../../platform/api/userAddress/models/response';
import ROUTES from '../../../../../../../platform/constants/routes';

import './style.scss';
import HelperComponent from '../../../../../../../platform/classes/helper-component';

interface IProps {
  isActiveId?:number;
  selectedId?:number
  data: IUserAddressListResponseModel[];
  onEditDefault?(id: number): Promise<void>;
  onRowClick?(item: any): any;
  onRemove?(id: number): Promise<void>;
  onHandleChoose?(item:any): any;
};


class List extends HelperComponent<IProps, {}> {
  public render() {
    const { data, onRowClick, onRemove, isActiveId } = this.props;
    return (<>
      {data && data.map((item: IUserAddressListResponseModel, index: number) =>
        <div className={`P-list-item P-list-item-box ${item.id === isActiveId || (this.props.selectedId === item.id && !isActiveId)? 'isActive' : ''}`} key={index} onClick={() => {
          !!onRowClick && onRowClick(item)
        }}>
          <div className="G-flex G-flex-column P-address-det">
            <div className="nameAddress_container">
              <span className='nameAddress'>{item.name}</span>
              <div className="edit_container">
                <Link to={ROUTES.PROFILE.ADDRESSES.UPDATE.replace(':id', `${item.id}`)}>
                  <MdOutlineModeEditOutline/>
                </Link>
                <RiDeleteBin5Line color="#ff0000c7" onClick={(e) => {
                  e.stopPropagation()
                  onRemove && onRemove(item.id)
                }}/>
              </div>
            </div>

            <p className="sdress_icon">
              <img src={addressIcon}/>
              <span className='addr'>{item.addressText}</span>
            </p>
          </div>
        </div>)}
    </>);
  }

}


export default List;
