import * as React  from 'react';
import "./shimmer.scss"
 
function ShimmerLoading({style,colCount=1,className='',childColCount=0,MClass=''}:any) {
    return ( 
        <div className={`cardShimmer ${MClass}`}>
            {Array.from({length:colCount},(_,index) => ( 
                <div key={index} className={`shimmerBG media shimmer${index} ${className}`} style={style}>
                    {Array.from({length:childColCount},(value:any,indexI:number) => ( 
                        <div key={indexI} className={`shimmerBG shimmerChildMain shimmerChild${indexI}`} style={style}><span>''</span></div>
                    ))}
                </div>
            ))}
        </div>
    );
}
 
export default ShimmerLoading;